import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../../styles/theme';

export const useStyles = makeStyles(() => ({
  titleBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '9px',
    marginBottom: '25px',
    '& h6': {
      marginLeft: '10px',
      marginRight: '10px',
    },
    '& svg': {
      fill: '#FF6347',
    },
  },
  list: {
    textAlign: 'left',
    paddingLeft: '0px',
    '& li': {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '13px',
      '& a': {
        color: theme.colors.primary,
      },
    },
    '& .icon': {
      marginRight: '11px',
    },
  },
}));
