import { combineReducers } from 'redux';

import auth from './auth';
import user from './user';
import boxes from './boxes';
import header from './header';

export default combineReducers({
  auth,
  user,
  boxes,
  header,
});
