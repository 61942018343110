import { Box, Tooltip } from '@material-ui/core';
import { styled, makeStyles, withStyles } from '@material-ui/core/styles';

export const Logo = styled(Box)({
  display: 'flex',
});

export const TotalBalance = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '22%',
});

export const MenuRight = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  minWidth: '90px',
  width: '38%',
});

export const HeaderTooltip = withStyles({
  tooltip: {
    fontSize: '11px',
  },
})(Tooltip);

export const useStyles = makeStyles({
  avatar: {
    color: '#fff9',
    marginLeft: '10px',
    marginRight: '5px',
    cursor: 'pointer',
  },
  container: {
    height: '60px',
    paddingRight: '9px',
    paddingLeft: '7px',
    maxWidth: '100%',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#2C325C',
    boxShadow: '0px 0px 10px #00000033',
    zIndex: 1,
  },
});
