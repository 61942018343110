import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core';
import { getDaysInMonth } from 'date-fns';

import BaseBox from '../_Common/BaseBox';
import {
  Body,
  GoalItem,
  Title,
  MoneyProgress,
  BoxProgress,
  BarProgress,
  Progress,
} from './GoalsStyles';
import CustomCircularProgress from '../_Common/CustomCircularProgress';
import { formatPrice } from '../../../../../utils/functions';
import { ApplicationState } from '../../../../../store';

interface Config {
  tradeInWeekend: boolean;
  goalOfMonth: number;
  currency: string;
}

interface GoalsInterface {
  config: Config;
  progressToday: number;
  progressWeek: number;
  progressMonth: number;
}

const initialState = {
  config: {
    tradeInWeekend: true,
    goalOfMonth: 0,
    currency: 'BRL',
  },
  progressToday: 0,
  progressWeek: 0,
  progressMonth: 0,
};

export default function Goals() {
  const [goals, setGoals] = useState<GoalsInterface>(initialState);
  const userState = useSelector((state: ApplicationState) => state.user);
  const { loading, boxes } = useSelector(
    (state: ApplicationState) => state.boxes
  );

  useEffect(() => {
    if (boxes.Goals) {
      setGoals(boxes.Goals);
    }
  }, [boxes.Goals]);

  const theme = useTheme();

  function goalBasedOnMonth(divider: number): number {
    return Math.round((userState.user.config.goalOfMonth / divider) * 1e2) / 1e2;
  }

  function percentCompleted(value: number, goal: number): number {
    return Math.round(((value * 100) / goal) * 1e1) / 1e1;
  }

  function absPercentCompleted(value: number, goal: number): number {
    const roundedValue = Math.round(((value * 100) / goal) * 1e1) / 1e1;
    if (roundedValue > 100 || roundedValue < -100) {
      return 100;
    }
    return Math.abs(Math.round(((value * 100) / goal) * 1e1) / 1e1);
  }

  const goalToday = goalBasedOnMonth(getDaysInMonth(new Date()));
  const goalWeek = goalBasedOnMonth(4);

  const absPercentDay =
    absPercentCompleted(goals.progressToday, goalToday) || 0;
  const absPercentWeek = absPercentCompleted(goals.progressWeek, goalWeek) || 0;
  const absPercentMonth =
    absPercentCompleted(goals.progressMonth, userState.user.config.goalOfMonth) || 0;

  const percentDay = percentCompleted(goals.progressToday, goalToday);
  const percentWeek = percentCompleted(goals.progressWeek, goalWeek);
  const percentMonth = percentCompleted(
    goals.progressMonth,
    userState.user.config.goalOfMonth
  );

  return (
    <BaseBox title="Metas">
      {!loading && (
        <Body>
          <GoalItem>
            <Title variant="body1">Diária</Title>
            <BoxProgress>
              <MoneyProgress variant="body2">{`${goals.progressToday} de ${formatPrice(goalToday)}`}</MoneyProgress>
              <BarProgress>
                <Progress
                  value={absPercentDay}
                  color={`${
                    goals.progressToday > 0
                      ? theme.colors.win
                      : theme.colors.loss
                  }`}
                />
                <span>{`${percentDay}%`}</span>
              </BarProgress>
            </BoxProgress>
          </GoalItem>

          <GoalItem>
            <Title variant="body1">Semanal</Title>
            <BoxProgress>
              <MoneyProgress variant="body2">{`${goals.progressWeek} de ${formatPrice(goalWeek)}`}</MoneyProgress>
              <BarProgress>
                <Progress
                  value={absPercentWeek}
                  color={`${
                    goals.progressWeek > 0
                      ? theme.colors.win
                      : theme.colors.loss
                  }`}
                />
                <span>{`${percentWeek}%`}</span>
              </BarProgress>
            </BoxProgress>
          </GoalItem>

          <GoalItem>
            <Title variant="body1">Mensal</Title>
            <BoxProgress>
              <MoneyProgress variant="body2">{`${goals.progressMonth} de ${formatPrice(userState.user.config.goalOfMonth)}`}</MoneyProgress>
              <BarProgress>
                <Progress
                  value={absPercentMonth}
                  color={`${
                    goals.progressMonth > 0
                      ? theme.colors.win
                      : theme.colors.loss
                  }`}
                />
                <span>{`${percentMonth}%`}</span>
              </BarProgress>
            </BoxProgress>
          </GoalItem>
        </Body>
      )}
      {loading && <CustomCircularProgress />}
    </BaseBox>
  );
}
