import { styled, Box } from '@material-ui/core';

export const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px 5px 0px 5px',
  minHeight: '226px',
  maxHeight: '226px'
});
