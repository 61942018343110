import React from 'react';
import { call, put, all, takeLatest } from 'redux-saga/effects';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import history from '../../../services/history';
import { signInSuccess, signInFailure } from './actions';
import { AuthTypes } from './types';

export function* signIn({
  type, // eslint-disable-line
  payload,
}: {
  payload: any;
  type: typeof AuthTypes.SIGN_IN_REQUEST;
}) {
  const { email, password } = payload;

  try {
    const response = yield call(api.post, 'session', {
      email,
      password,
    });
    const { user, token } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(user, token));

    toast.dismiss();
    history.push('/');
  } catch (err) {
    if (err.message === 'Network Error') {
      toast.error(({ closeToast }: { closeToast: any }) => ( // eslint-disable-line
        <div style={{ display: 'flex', alignContent: 'center' }}>
          <ErrorOutlineIcon style={{ marginTop: '6px' }} />
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              marginLeft: '7px',
            }}
          >
            Erro ao conectar ao servidor, tente novamente em alguns minutos.
          </div>
        </div>
      ));
    } else {
      toast.error(err.response.data.error);
    }
    yield put(signInFailure());
  }
}

export function setToken({ type, payload }: { payload: any; type: any }) { // eslint-disable-line
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function logOut() {
  history.push('/login');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
  takeLatest(AuthTypes.LOGOUT, logOut),
]);
