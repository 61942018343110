import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  titleWin: {
    width: '30px',
    marginRight: '35px',
    textAlign: 'center',
  },
  titleLoss: {
    width: '30px',
    marginRight: '25px',
    textAlign: 'center',
  },
  titleDraw: {
    width: '30px',
    marginRight: '28px',
    textAlign: 'center',
  },
  titlePercent: {
    width: '30px',
    marginRight: '39px',
    textAlign: 'center',
  },
  boxTitles: {
    background: 'transparent !important',
  },
});
