import { styled, makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Tooltip } from '@material-ui/core';

export const Container = styled(Box)({
  height: '100%',
  width: '50px',
  position: 'fixed',
  paddingTop: '70px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& button': {
    marginBottom: '3px',
    padding: '7px',
  },
});

export const IconTooltip = withStyles({
  tooltip: {
    fontSize: '11px',
  },
})(Tooltip);

export const TopIcons = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
});

export const BottomIcons = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
});

export const SeeTutorials = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100px',
  width: '300px',
  background: '#23284b',
  position: 'absolute',
  left: '60px',
  bottom: '15px',
  borderRadius: '4px',
  boxShadow: '1px 1px 10px #0000004d',
  padding: '0 23px',

  '& h4': {
    fontSize: '18px',
    color: '#ffee58',
    marginBottom: '5px',
    marginTop: '0px',
  },
  '& p': {
    margin: '0',
  }
});

export const useStyles = makeStyles(theme => ({
  add: {
    width: '36px',
    height: '36px',
    marginTop: '5px',
    padding: '0px !important',
    '& svg': {
      marginBottom: '0px',
    },
  },
  hr: {
    width: '100%',
    border: '0.7px solid #ffffff17',
    marginBottom: '7px',
  },
  container: {
    background: theme.colors.backgroundLight,
  },
  arrowTutorial: {
    position: 'absolute',
    left: '-36px',
    fontSize: '60px',
    top: '21px',
    color: '#ffee58',
  },
}));
