import { Reducer } from 'redux';
import produce from 'immer';
import { BoxesState, BoxesTypes } from './types';
import { AuthTypes } from '../auth/types';

const INITIAL_STATE: BoxesState = {
  // eslint-disable-next-line
  // @ts-ignore
  boxes: {},
  loading: true,
  error: false,
  pendingRequest: true,
};

const boxes: Reducer<BoxesState> = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case BoxesTypes.BOXES_REQUEST: {
        draft.loading = true;
        break;
      }
      case BoxesTypes.BOXES_SUCCESS: {
        draft.boxes = action.payload.boxes;
        draft.loading = false;
        draft.pendingRequest = false;
        break;
      }
      case BoxesTypes.BOXES_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      case BoxesTypes.BOXES_SETPENDING: {
        draft.pendingRequest = true;
        break;
      }
      case AuthTypes.LOGOUT: {
        draft.boxes = INITIAL_STATE.boxes;
        break;
      }
      default:
    }
  });
};

export default boxes;
