import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import { headerSuccess, headerFailure } from './actions';
import { HeaderTypes } from './types';

export function* getBalance() {
  try {
    const response = yield call(api.get, '/balance');
    const { balance } = response.data;

    yield put(headerSuccess(balance));
  } catch (err) {
    toast.error(err.response.message, {
      toastId: 'headerError',
    });

    yield put(headerFailure());
  }
}

export default all([takeLatest(HeaderTypes.HEADER_REQUEST, getBalance)]);
