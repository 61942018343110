import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';

import BaseBox from '../_Common/BaseBox';
import {
  BodyBox,
  RowBox,
  ValueBox,
  TextWin,
  TextLoss,
} from '../_Common/CommonComponents';
import NoData from '../_Common/NoData';
import { useStyles } from './ProfitAndLossStyles';
import CustomCircularProgress from '../_Common/CustomCircularProgress';
import { ApplicationState } from '../../../../../store';
import { ProfitAndLossInterface } from '../../../../../store/modules/boxes/types';
import { formatPrice, setPriceColor } from '../../../../../utils/functions';

export default function ProfitAndLoss() {
  const initialData = {
    today: {
      profit: 0,
      loss: 0,
      total: 0,
      totalColor: '#fff',
    },
    week: {
      profit: 0,
      loss: 0,
      total: 0,
      totalColor: '#fff',
    },
    month: {
      profit: 0,
      loss: 0,
      total: 0,
      totalColor: '#fff',
    },
    year: {
      profit: 0,
      loss: 0,
      total: 0,
      totalColor: '#fff',
    },
    allTime: {
      profit: 0,
      loss: 0,
      total: 0,
      totalColor: '#fff',
    },
  };
  const classes = useStyles();
  const [parsedProfitAndLoss, setParsedProfitAndLoss] = useState<
    ProfitAndLossInterface
  >(initialData);
  const { loading, boxes } = useSelector(
    (state: ApplicationState) => state.boxes
  );

  useEffect(() => {
    if (boxes.ProfitAndLoss) {
      const parsedData: ProfitAndLossInterface = {
        today: {
          profit: formatPrice(boxes.ProfitAndLoss.today.profit),
          loss: formatPrice(boxes.ProfitAndLoss.today.loss),
          total: formatPrice(boxes.ProfitAndLoss.today.total),
          totalColor: setPriceColor(boxes.ProfitAndLoss.today.total),
        },
        week: {
          profit: formatPrice(boxes.ProfitAndLoss.week.profit),
          loss: formatPrice(boxes.ProfitAndLoss.week.loss),
          total: formatPrice(boxes.ProfitAndLoss.week.total),
          totalColor: setPriceColor(boxes.ProfitAndLoss.week.total),
        },
        month: {
          profit: formatPrice(boxes.ProfitAndLoss.month.profit),
          loss: formatPrice(boxes.ProfitAndLoss.month.loss),
          total: formatPrice(boxes.ProfitAndLoss.month.total),
          totalColor: setPriceColor(boxes.ProfitAndLoss.month.total),
        },
        year: {
          profit: formatPrice(boxes.ProfitAndLoss.year.profit),
          loss: formatPrice(boxes.ProfitAndLoss.year.loss),
          total: formatPrice(boxes.ProfitAndLoss.year.total),
          totalColor: setPriceColor(boxes.ProfitAndLoss.year.total),
        },
        allTime: {
          profit: formatPrice(boxes.ProfitAndLoss.allTime.profit),
          loss: formatPrice(boxes.ProfitAndLoss.allTime.loss),
          total: formatPrice(boxes.ProfitAndLoss.allTime.total),
          totalColor: setPriceColor(boxes.ProfitAndLoss.allTime.total),
        },
      };
      setParsedProfitAndLoss(parsedData);
    }
  }, [boxes.ProfitAndLoss]);

  return (
    <BaseBox title="Lucro/Prejuízo">
      {!loading && parsedProfitAndLoss.allTime.loss !== 0 && (
        <BodyBox>
          <Box>
            <RowBox>
              <TextWin className={classes.titleProfit} variant="body2">
                Lucro
              </TextWin>
              <TextLoss className={classes.titleLoss} variant="body2">
                Prejuízo
              </TextLoss>
              <Typography className={classes.titleTotal} variant="body2">
                Total
              </Typography>
            </RowBox>
            <RowBox>
              <Box className={classes.boxTitles}>Hoje</Box>
              <ValueBox className={classes.valueBox}>
                <Typography variant="body2">
                  {parsedProfitAndLoss.today.profit}
                </Typography>
              </ValueBox>
              <ValueBox className={classes.valueBox}>
                <Typography variant="body2">
                  {parsedProfitAndLoss.today.loss}
                </Typography>
              </ValueBox>
              <ValueBox className={classes.valueBox}>
                <Typography
                  variant="body2"
                  style={{
                    color: `${parsedProfitAndLoss.today.totalColor}`,
                  }}
                >
                  {parsedProfitAndLoss.today.total}
                </Typography>
              </ValueBox>
            </RowBox>
            <RowBox>
              <Box className={classes.boxTitles}>Semana</Box>
              <ValueBox className={classes.valueBox}>
                <Typography variant="body2">
                  {parsedProfitAndLoss.week.profit}
                </Typography>
              </ValueBox>
              <ValueBox className={classes.valueBox}>
                <Typography variant="body2">
                  {parsedProfitAndLoss.week.loss}
                </Typography>
              </ValueBox>
              <ValueBox className={classes.valueBox}>
                <Typography
                  variant="body2"
                  style={{
                    color: `${parsedProfitAndLoss.week.totalColor}`,
                  }}
                >
                  {parsedProfitAndLoss.week.total}
                </Typography>
              </ValueBox>
            </RowBox>
            <RowBox>
              <Box className={classes.boxTitles}>No mês</Box>
              <ValueBox className={classes.valueBox}>
                <Typography variant="body2">
                  {parsedProfitAndLoss.month.profit}
                </Typography>
              </ValueBox>
              <ValueBox className={classes.valueBox}>
                <Typography variant="body2">
                  {parsedProfitAndLoss.month.loss}
                </Typography>
              </ValueBox>
              <ValueBox className={classes.valueBox}>
                <Typography
                  variant="body2"
                  style={{
                    color: `${parsedProfitAndLoss.month.totalColor}`,
                  }}
                >
                  {parsedProfitAndLoss.month.total}
                </Typography>
              </ValueBox>
            </RowBox>
            <RowBox>
              <Box className={classes.boxTitles}>No ano</Box>
              <ValueBox className={classes.valueBox}>
                <Typography variant="body2">
                  {parsedProfitAndLoss.year.profit}
                </Typography>
              </ValueBox>
              <ValueBox className={classes.valueBox}>
                <Typography variant="body2">
                  {parsedProfitAndLoss.year.loss}
                </Typography>
              </ValueBox>
              <ValueBox className={classes.valueBox}>
                <Typography
                  variant="body2"
                  style={{
                    color: `${parsedProfitAndLoss.year.totalColor}`,
                  }}
                >
                  {parsedProfitAndLoss.year.total}
                </Typography>
              </ValueBox>
            </RowBox>
            <RowBox>
              <Box className={classes.boxTitles}>Todos</Box>
              <ValueBox className={classes.valueBox}>
                <Typography variant="body2">
                  {parsedProfitAndLoss.allTime.profit}
                </Typography>
              </ValueBox>
              <ValueBox className={classes.valueBox}>
                <Typography variant="body2">
                  {parsedProfitAndLoss.allTime.loss}
                </Typography>
              </ValueBox>
              <ValueBox className={classes.valueBox}>
                <Typography
                  variant="body2"
                  style={{
                    color: `${parsedProfitAndLoss.allTime.totalColor}`,
                  }}
                >
                  {parsedProfitAndLoss.allTime.total}
                </Typography>
              </ValueBox>
            </RowBox>
          </Box>
        </BodyBox>
      )}
      {loading && <CustomCircularProgress />}
      {!loading &&
        parsedProfitAndLoss.allTime.loss === 0 &&
        parsedProfitAndLoss.allTime.profit === 0 && <NoData />}
    </BaseBox>
  );
}
