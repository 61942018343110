import React from 'react';
import { Typography } from '@material-ui/core';

import { GridItems, Container, Header, Body } from './BaseBoxStyles';

export default function BaseBox({
  title,
  children,
}: {
  title: string;
  children: any;
}) {
  return (
    <GridItems xs={12} sm={6} md={6} lg={4} item>
      <Container>
        <Header>
          <Typography variant="subtitle1" component="h4">
            {title}
          </Typography>
        </Header>
        <Body>{children}</Body>
      </Container>
    </GridItems>
  );
}
