import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import SignedLayout from '../pages/Private/_signedLayout';
import { store } from '../store';
import { addDays, differenceInCalendarDays } from 'date-fns';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  path,
  exact,
  ...rest
}: {
  component: any;
  isPrivate?: boolean;
  path?: string;
  exact?: boolean;
}) {
  const { signed } = store.getState().auth;
  const { isActive, createdAt } = store.getState().user.user;
  const trialOrActive =
    differenceInCalendarDays(addDays(new Date(createdAt), 7), new Date()) > 0 ||
    isActive;

  if (!signed && isPrivate) {
    return <Redirect to="/login" />;
  }

  if (signed && (path === '/login' || path === '/register') && trialOrActive) {
    return <Redirect to="/" />;
  }
  if (signed && isPrivate && !trialOrActive) {
    return <Redirect to="/trialExpired" />;
  }

  if (signed && isPrivate) {
    return (
      <Route
        {...rest}
        exact={exact}
        path={path}
        render={props => (
          <SignedLayout>
            <Component {...props} />
          </SignedLayout>
        )}
      />
    );
  }

  return <Route {...rest} exact={exact} path={path} component={Component} />; // eslint-disable-line
}

RouteWrapper.defaultProps = {
  isPrivate: false,
  exact: false,
  path: '',
};
