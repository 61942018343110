import React from 'react';
import { styled, Box, Typography, LinearProgress } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import theme from '../../../../../styles/theme';

export const Body = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const GoalItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.background,
  padding: '5px 10px',
  borderRadius: theme.borderRadius,
  width: '95%',
  marginTop: '10px',
  '&:hover': {
    backgroundColor: '#1a1c38',
  },
});

export const Title = styled(Typography)({
  width: '100%',
  padding: '2px 0px 4px',
});

export const MoneyProgress = styled(Typography)({
  whiteSpace: 'nowrap',
  marginRight: '20px',
});

export const BoxProgress = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

export const BarProgress = styled(Box)({
  position: 'relative',
  width: '100%',
  '& span': {
    position: 'absolute',
    right: '12px',
    top: '4px',
  },
});

export function Progress(props: any) {
  const { color, value } = props;

  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    bar: {
      backgroundColor: color,
    },
  });
  const classes = useStyles();
  return (
    <BasicProgress
      variant="determinate"
      value={value}
      classes={{ bar: classes.bar }}
    />
  );
}

export const BasicProgress = withStyles({
  root: {
    height: 25,
    backgroundColor: theme.colors.backgroundLight,
    width: '100%',
    borderRadius: 20,
  },
  bar: {
    borderRadius: 20,
  },
})(LinearProgress);
