import { styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

export const GenerateNewToken = styled(Box)({
  display: 'flex',
  marginTop: '19px',
  '& button': {
    background: 'none',
    color: '#bbb',
    border: 'none',
  },
});
