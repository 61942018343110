import { Box } from '@material-ui/core';
import { styled, makeStyles } from '@material-ui/core/styles';

export const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#2c325c',
  width: '100%',
  position: 'fixed',
});

export const Logo = styled(Box)({
  display: 'flex',
  flex: 1,
});

export const MenuRight = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  minWidth: '90px',
  flex: 1,
});

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    minHeight: '45px',
    alignItems: 'center'
  },
  button: {
    color: '#fff',
    border: '1px solid #fff',
    padding: '6px 15px',
    borderRadius: '7px',
    marginRight: '10px',
    transition: '.2s',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#1e2141',
    },
  },
});
