import { styled, Typography, Box, Container } from '@material-ui/core';
import theme from '../../../../../styles/theme';

export const TextWin = styled(Typography)({
  color: theme.colors.win,
});

export const TextLoss = styled(Typography)({
  color: theme.colors.loss,
});

export const ValueBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60px',
  height: '30px',
  borderRadius: '15px',
  backgroundColor: theme.colors.background,
  marginLeft: '7px',
  '&:hover': {
    backgroundColor: '#1a1c38',
  },
});

export const RowBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginTop: '10px',
  '&:hover .MuiBox-root': {
    background: '#1a1c38',
  },
});

export const BodyBox = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  '& div > div:nth-child(2)': {
    marginTop: '1px',
  },
});
