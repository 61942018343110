import React, { useState } from 'react';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Link } from 'react-router-dom';
import { Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import {
  Container,
  BackgroundWave,
  LeftImg,
  FormContainer,
  FormLogin,
  Icon,
} from './RegisterStyles';
import personSvg from './Assets/bg.svg';
import wave from './Assets/wave.png';
import VerifyEmailModal from './VerifyEmailDialog';
import Header from '../Components/Header';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
  phone: Yup.string().required('O telefone é obrigatório'),
  name: Yup.string().required('O nome é obrigatório'),
});

export default function Register() {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');
  const [nameFocus, setNameFocus] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [phoneValue, setPhoneValue] = useState('');

  // function openVerifyModal() {
  //   setOpenModal(true);
  // }

  function closeVerifyModal() {
    setOpenModal(false);
  }

  function handleSubmit(data: any): void {
    const { email, password, name, phone } = data;
    setLoading(true);

      api
        .post('register', {
          name,
          phone,
          email,
          password
        })
        .then(res => {
          setLoading(false);
          // openVerifyModal()
          toast.success(res.data.message);
        })
        .catch(err => {
          setLoading(false);
          toast.error(err.response.data.error);
        });
  }

  function onInputFocus(type: string): void {
    if (type === 'email') {
      setEmailFocus(true);
    } else if (type === 'name') {
      setNameFocus(true);
    } else if (type === 'phone') {
      setPhoneFocus(true);
    } else {
      setPasswordFocus(true);
    }
  }
  function onInputBlur(type: string): void {
    if (type === 'email' && !emailValue) {
      setEmailFocus(false);
    }
    if (type === 'password' && !passwordValue) {
      setPasswordFocus(false);
    }
    if (type === 'phone' && !phoneValue) {
      setPhoneFocus(false);
    }
    if (type === 'name' && !nameValue) {
      setNameFocus(false);
    }
  }

  return (
    <>
    <Header />
      <BackgroundWave src={wave} className="negative-zIndex" alt="background" />
      <Container>
        <LeftImg>
          <img src={personSvg} alt="loginPerson" />
        </LeftImg>
        <FormContainer>
          <FormLogin onSubmit={handleSubmit} schema={schema}>
            <Typography color="primary" variant="h4">
              Cadastre-se gratuitamente!
            </Typography>

            <div className={`input-div one ${nameFocus ? 'focus' : ''}`}>
              <Icon>
                <PersonRoundedIcon fontSize="small" />
              </Icon>
              <div className="div">
                <h5>Nome</h5>
                <Input
                  onFocus={() => onInputFocus('name')}
                  onBlur={() => onInputBlur('name')}
                  name="name"
                  type="text"
                  autoFocus
                  spellCheck={false}
                  onChange={(e: any) => setNameValue(e.target.value)}
                />
              </div>
            </div>
            
            <div className={`input-div one ${phoneFocus ? 'focus' : ''}`}>
              <Icon>
                <WhatsAppIcon fontSize="small" />
              </Icon>
              <div className="div">
                <h5>Celular</h5>
                <Input
                  onFocus={() => onInputFocus('phone')}
                  onBlur={() => onInputBlur('phone')}
                  name="phone"
                  type="text"
                  autoFocus
                  spellCheck={false}
                  onChange={(e: any) => setPhoneValue(e.target.value)}
                />
              </div>
            </div>

            <div className={`input-div one ${emailFocus ? 'focus' : ''}`}>
              <Icon>
                <EmailRoundedIcon fontSize="small" />
              </Icon>
              <div className="div">
                <h5>Email</h5>
                <Input
                  onFocus={() => onInputFocus('email')}
                  onBlur={() => onInputBlur('email')}
                  name="email"
                  type="email"
                  spellCheck={false}
                  onChange={(e: any) => setEmailValue(e.target.value)}
                />
              </div>
            </div>

            <div className={`input-div pass ${passwordFocus ? 'focus' : ''}`}>
              <Icon>
                <LockRoundedIcon fontSize="small" />
              </Icon>
              <div className="div">
                <h5>Senha</h5>
                <Input
                  onFocus={() => onInputFocus('password')}
                  onBlur={() => onInputBlur('password')}
                  name="password"
                  type="password"
                  onChange={(e: any) => setPasswordValue(e.target.value)}
                />
              </div>
            </div>
            <Button
              className="submitButton"
              variant="contained"
              color="primary"
              type="submit"
              disabled={!!loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Cadastrar'}
            </Button>
            <Link to="/login" style={{ color: '#fff' }}>Já possui uma conta? <Typography color="primary" variant="body2" style={{ display: 'inline-block' }}>Entrar agora</Typography></Link>
          </FormLogin>
        </FormContainer>
      </Container>
      <VerifyEmailModal open={openModal} closeModal={closeVerifyModal}/>

    </>
  );
}
