import { styled, Box, makeStyles } from '@material-ui/core';
import theme from '../../../styles/theme';

export const BoxAdd = styled(Box)({
  width: '90%',
  padding: '10px',
  marginTop: '20px',
  background: theme.colors.backgroundLight,
  borderRadius: '4px',
});

export const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  paper: {
    marginTop: '20px',
    background: theme.colors.backgroundLight,
    width: '90%',
  },
  title: {
    marginTop: '15px',
  },
  row: {
    '&:nth-child(odd)': {
      backgroundColor: '#ffffff0f',
    },
  },
  input: {
    minWidth: '164px',
  },
  boxAddButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '12px',
  },
  addButton: {
    width: '36px',
    height: '36px',
  },
});
