import React, { HTMLAttributes } from 'react';
import {
  createStyles,
  emphasize,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { ControlProps } from 'react-select/src/components/Control';
import { MenuProps, NoticeProps } from 'react-select/src/components/Menu';
import { OptionProps } from 'react-select/src/components/Option';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';

interface OptionType {
  label: string;
  value: string;
}

const suggestions: OptionType[] = [
  { label: 'EUR/USD' },
  { label: 'EUR/JPY' },
  { label: 'EUR/CAD' },
  { label: 'EUR/AUD' },
  { label: 'EUR/CHF' },
  { label: 'EUR/GBP' },
  { label: 'EUR/NZD' },
  { label: 'USD/JPY' },
  { label: 'USD/CAD' },
  { label: 'USD/NOK' },
  { label: 'USD/CHF' },
  { label: 'AUD/USD' },
  { label: 'AUD/JPY' },
  { label: 'AUD/CAD' },
  { label: 'AUD/CHF' },
  { label: 'AUD/NZD' },
  { label: 'GBP/JPY' },
  { label: 'GBP/CAD' },
  { label: 'GBP/CHF' },
  { label: 'GBP/USD' },
  { label: 'GBP/NZD' },
  { label: 'GBP/AUD' },
  { label: 'NZD/USD' },
  { label: 'CAD/CHF' },
  { label: 'CAD/JPY' },
  { label: 'EUR/USD (OTC)' },
  { label: 'EUR/GBP (OTC)' },
  { label: 'EUR/JPY (OTC)' },
  { label: 'NZD/USD (OTC)' },
  { label: 'AUD/CAD (OTC)' },
  { label: 'GBP/USD (OTC)' },
  { label: 'GBP/JPY (OTC)' },
  { label: 'USD/CHF (OTC)' },
  { label: 'USD/JPY (OTC)' },
  { label: 'Morg Stan' },
  { label: 'Alibaba' },
  { label: 'Facebook' },
  { label: 'CitiGroup' },
  { label: 'Baidu' },
  { label: 'Intel' },
  { label: 'Nike' },
  { label: 'McDonalds' },
  { label: 'Coca Cola' },
  { label: 'Apple' },
  { label: 'Microsoft' },
  { label: 'Goldman S' },
  { label: 'JP Morgan C' },
  { label: 'Google' },
  { label: 'Twitter' },
  { label: 'Amazon' },
  { label: 'Tesla' },
  { label: 'Yandex' },
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}));

const useStylesAsset = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 55,
      minWidth: 170,
    },
    input: {
      display: 'flex',
      padding: 0,
      height: 'auto',
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08
      ),
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2),
    },
    singleValue: {
      fontSize: 16,
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      bottom: 6,
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
      backgroundColor: theme.colors.background,
      maxHeight: '220px',
      '& div': {
        maxHeight: '220px',
      },
    },
  })
);

function NoOptionsMessage(props: NoticeProps<OptionType>) {
  const { selectProps, innerProps, children } = props;

  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> &
  HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<OptionType>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

function Option(props: OptionProps<OptionType>) {
  const { innerRef, isFocused, isSelected, innerProps, children } = props;

  return (
    <MenuItem
      ref={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
}

type MuiPlaceholderProps = Omit<PlaceholderProps<OptionType>, 'innerProps'> &
  Partial<Pick<PlaceholderProps<OptionType>, 'innerProps'>>;
function Placeholder(props: MuiPlaceholderProps) {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function SingleValue(props: SingleValueProps<OptionType>) {
  const { selectProps, innerProps, children } = props;

  return (
    <Typography className={selectProps.classes.singleValue} {...innerProps}>
      {children}
    </Typography>
  );
}

function ValueContainer(props: ValueContainerProps<OptionType>) {
  const { selectProps, children } = props;
  return <div className={selectProps.classes.valueContainer}>{children}</div>;
}

function Menu(props: MenuProps<OptionType>) {
  const { selectProps, innerProps, children } = props;
  return (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      {children}
    </Paper>
  );
}

export default {
  components: {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
  },
  suggestions,
  useStylesAsset,
};
