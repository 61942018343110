export const AuthTypes = {
  SIGN_IN_REQUEST: '@auth/SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS: '@auth/SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: '@auth/SIGN_IN_FAILURE',
  LOGOUT: '@auth/LOGOUT',
};

export interface AuthState {
  token: string;
  signed: boolean;
  loading: boolean;
  error: boolean;
}
