import axios from 'axios';
import { store } from '../store'; // eslint-disable-line

import history from './history';
import { logOut } from '../store/modules/auth/actions';

const api = axios.create({
  baseURL: 'https://api.dashtrader.com.br',
  // baseURL: 'http://localhost:3333',
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch(logOut());
      history.push('/');
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default api;
