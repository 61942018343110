import { action } from 'typesafe-actions';
import { AuthTypes, AuthState } from './types';
import { User } from '../user/types';

export const signInRequest = (email: string, password: string) =>
  action(AuthTypes.SIGN_IN_REQUEST, { email, password });

export const signInSuccess = (user: User, token: AuthState) =>
  action(AuthTypes.SIGN_IN_SUCCESS, { user, token });

export const signInFailure = () => action(AuthTypes.SIGN_IN_FAILURE);

export const logOut = () => action(AuthTypes.LOGOUT);
