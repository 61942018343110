import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
} from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { headerAdd } from '../../../store/modules/header/actions';
import { boxesSetPending } from '../../../store/modules/boxes/actions';
import { useStyles } from './TradesHistoryStyles';
import {
  dateToTimezone,
  formatPrice,
  correctInstrument,
  setPriceColor,
} from '../../../utils/functions';

export default function TradesHistory() {
  interface Trade {
    _id: string;
    date: string;
    asset: string;
    instrument: string;
    investment: number;
    result: number;
    formattedInvestment: number;
    formattedResult: number;
    color: string;
  }

  const [trades, setTrades] = useState<Array<Trade>>([]);

  useEffect(() => {
    api.get('/tradeHistory').then(res => {
      const { data } = res;
      const parsedData = data.map((item: any) => {
        return {
          ...item,
          date: dateToTimezone(item.date),
          instrument: correctInstrument(item.instrument),
          formattedInvestment: formatPrice(item.investment),
          formattedResult: formatPrice(item.result),
          color: setPriceColor(item.result),
        };
      });

      setTrades(parsedData);
    });
  }, []);

  const dispatch = useDispatch();

  function handleDelete(trade: Trade) {
    api
      .delete('tradeHistory', {
        data: {
          _id: trade._id,
        },
      })
      .then(res => {
        toast.success(res.data.message);

        setTrades(trades.filter(a => a._id !== trade._id));

        dispatch(boxesSetPending());
        dispatch(headerAdd(trade.result * -1));
      })
      .catch(err => {
        toast.error(err.response.data.error);
      });
  }

  const classes = useStyles();
  return (
    <>
      <Typography variant="h5" className={classes.title}>
        Histórico de trades
      </Typography>
      <TableContainer component={Paper} className={classes.paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell align="center">Ativo</TableCell>
              <TableCell align="center">Tipo</TableCell>
              <TableCell align="center">Investimento</TableCell>
              <TableCell align="center">Resultado</TableCell>
              <TableCell align="right">Remover</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trades.map(trade => (
              <TableRow className={classes.row} key={trade._id}>
                <TableCell component="th" scope="row">
                  {trade.date}
                </TableCell>
                <TableCell align="center">{trade.asset}</TableCell>
                <TableCell align="center">{trade.instrument}</TableCell>
                <TableCell align="center">
                  {trade.formattedInvestment}
                </TableCell>
                <TableCell align="center" style={{ color: trade.color }}>
                  {trade.formattedResult}
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleDelete(trade)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
