import { format, subHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import theme from '../styles/theme';
import { store } from '../store';

export function formatPrice(value: any): string {
  const { currency } = store.getState().user.user.config;
  if (value || value === 0) {
    if (currency === 'BRL') {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value);
    }
    return new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  }
  return value;
}

export function correctPricePtBrToEn(value: any) {
  const value1 = value.replace(',', '.');
  const value2 = value1.replace(',', '.');
  let value3 = value2;
  if (value2.split('.').length - 1 > 1) {
    value3 = value1.replace('.', '');
  }
  return value3;
}

export function setPriceColor(value: Number | string): string {
  if (value > 0) {
    return theme.colors.win;
  }
  if (value < 0) {
    return theme.colors.loss;
  }
  return '#fff';
}

export function setPercentColor(value: Number): string {
  if (value === null) {
    return '#fff';
  }
  if (value < 50) {
    return theme.colors.loss;
  }
  return theme.colors.win;
}

export function dateToTimezone(date: string): string {
  if (date) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const correctDate = utcToZonedTime(date, timezone);
    const formattedDate = format(correctDate, "HH':'mm' 'dd'/'MM'/'yyyy");

    return formattedDate;
  }
  return date;
}

export function hourToTimezone(hour: any): any {
  const timeZoneDate = new Date();
  const offsetHours = timeZoneDate.getTimezoneOffset() / 60;
  const date = new Date(2020, 2, 15, hour);
  const parsedDate = subHours(date, offsetHours);

  return parsedDate.getHours();
}

export function correctInstrument(instrument: string): string {
  switch (instrument) {
    case 'Binary Options':
      return 'Binárias';
    case 'Digital Options':
      return 'Digitais';
    case 'Forex':
      return 'Forex';
    default:
      return 'Erro';
  }
}
