import { Box } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';

export const SeeTutorials = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100px',
  width: '300px',
  background: '#23284b',
  position: 'absolute',
  right: '5%',
  bottom: '75px',
  borderRadius: '4px',
  boxShadow: '1px 1px 10px #0000004d',
  padding: '0 23px',

  '& h4': {
    fontSize: '18px',
    color: '#ffee58',
    marginBottom: '5px',
    marginTop: '0px',
  },
  '& p': {
    margin: '0',
  },
});

export const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: '0',
    background: '#2c325c',
    boxShadow: '0px 0px 10px #00000033',
    zIndex: 2,
    '& > button': {
      minWidth: '55px',
      maxWidth: '110px',
    },
  },
  arrowTutorial: {
    position: 'absolute',
    right: '48px',
    fontSize: '60px',
    bottom: '-35px',
    color: '#ffee58',
  },
});
