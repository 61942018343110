import React from 'react';
import 'typeface-roboto';
import ReactGA from 'react-ga4';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import theme from './styles/theme';
import './styles/globalStyles.css';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes';
import history from './services/history';
import { store, persistor } from './store';

const App: React.FC = () => {
  history.listen(location => {
    ReactGA.initialize('G-5951WWKBK9');
    ReactGA.set({ page: location.pathname }); // Update the user's current page
  });

  return (
    <MuiThemeProvider theme={theme}>
      <Router history={history}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Routes />
            <ToastContainer />
          </PersistGate>
        </Provider>
        <CssBaseline />
      </Router>
    </MuiThemeProvider>
  );
};

export default App;
