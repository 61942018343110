import { makeStyles } from '@material-ui/core';
import theme from '../../../styles/theme';

export const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  paper: {
    marginTop: '20px',
    background: theme.colors.backgroundLight,
    width: '90%',
  },
  title: {
    marginTop: '15px',
  },
  row: {
    '&:nth-child(odd)': {
      backgroundColor: '#ffffff0f',
    },
  },
});
