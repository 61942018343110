import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';

import BaseBox from '../_Common/BaseBox';
import {
  BodyBox,
  RowBox,
  ValueBox,
  TextWin,
  TextLoss,
} from '../_Common/CommonComponents';
import NoData from '../_Common/NoData';
import { useStyles } from './WinAndLossStyles';
import CustomCircularProgress from '../_Common/CustomCircularProgress';
import { ApplicationState } from '../../../../../store';
import {
  WinAndLossObj,
  WinAndLossInterface,
} from '../../../../../store/modules/boxes/types';
import { setPercentColor } from '../../../../../utils/functions';

export default function ProfitAndLoss() {
  const initialData = [
    {
      win: 0,
      loss: 0,
      draw: 0,
      percent: 0,
      percentColor: '#fff',
      name: 'Hoje',
    },
    {
      win: 0,
      loss: 0,
      draw: 0,
      percent: 0,
      percentColor: '#fff',
      name: 'Semana',
    },
    {
      win: 0,
      loss: 0,
      draw: 0,
      percent: 0,
      percentColor: '#fff',
      name: 'No mês',
    },
    {
      win: 0,
      loss: 0,
      draw: 0,
      percent: 0,
      percentColor: '#fff',
      name: 'No ano',
    },
    {
      win: 0,
      loss: 0,
      draw: 0,
      percent: 0,
      percentColor: '#fff',
      name: 'Todos',
    },
  ];
  const classes = useStyles();
  const [parsedWinAndLoss, setParsedWinAndLoss] = useState<
    Array<WinAndLossObj>
  >(initialData);
  const { loading, boxes } = useSelector(
    (state: ApplicationState) => state.boxes
  );

  useEffect(() => {
    if (boxes.WinAndLoss) {
      const parsedData: WinAndLossInterface = {
        today: {
          win: boxes.WinAndLoss.today.win,
          loss: boxes.WinAndLoss.today.loss,
          draw: boxes.WinAndLoss.today.draw,
          percent: boxes.WinAndLoss.today.percent,
          percentColor: setPercentColor(boxes.WinAndLoss.today.percent),
          name: 'Hoje',
        },
        week: {
          win: boxes.WinAndLoss.week.win,
          loss: boxes.WinAndLoss.week.loss,
          draw: boxes.WinAndLoss.week.draw,
          percent: boxes.WinAndLoss.week.percent,
          percentColor: setPercentColor(boxes.WinAndLoss.week.percent),
          name: 'Semana',
        },
        month: {
          win: boxes.WinAndLoss.month.win,
          loss: boxes.WinAndLoss.month.loss,
          draw: boxes.WinAndLoss.month.draw,
          percent: boxes.WinAndLoss.month.percent,
          percentColor: setPercentColor(boxes.WinAndLoss.month.percent),
          name: 'No mês',
        },
        year: {
          win: boxes.WinAndLoss.year.win,
          loss: boxes.WinAndLoss.year.loss,
          draw: boxes.WinAndLoss.year.draw,
          percent: boxes.WinAndLoss.year.percent,
          percentColor: setPercentColor(boxes.WinAndLoss.year.percent),
          name: 'No ano',
        },
        allTime: {
          win: boxes.WinAndLoss.allTime.win,
          loss: boxes.WinAndLoss.allTime.loss,
          draw: boxes.WinAndLoss.allTime.draw,
          percent: boxes.WinAndLoss.allTime.percent,
          percentColor: setPercentColor(boxes.WinAndLoss.allTime.percent),
          name: 'Todos',
        },
      };
      const parsedArrayData = [];
      parsedArrayData.push(parsedData.today);
      parsedArrayData.push(parsedData.week);
      parsedArrayData.push(parsedData.month);
      parsedArrayData.push(parsedData.year);
      parsedArrayData.push(parsedData.allTime);
      setParsedWinAndLoss(parsedArrayData);
    }
  }, [boxes.WinAndLoss]);

  return (
    <BaseBox title="Win/Loss">
      {!loading && (parsedWinAndLoss[4].loss !== 0 || parsedWinAndLoss[4].win !== 0) && (
        <BodyBox>
          <Box>
            <RowBox>
              <Typography className={classes.titlePercent} variant="body2">
                %
              </Typography>
              <TextWin className={classes.titleWin} variant="body2">
                Win
              </TextWin>
              <TextLoss className={classes.titleLoss} variant="body2">
                Loss
              </TextLoss>
              <Typography className={classes.titleDraw} variant="body2">
                Empates
              </Typography>
            </RowBox>
            {parsedWinAndLoss.map(item => (
              <RowBox>
                <Box className={classes.boxTitles}>{item.name}</Box>
                <ValueBox>
                  <Typography
                    variant="body2"
                    style={{
                      color: `${item.percentColor}`,
                    }}
                  >
                    {item.percent || 0}%
                  </Typography>
                </ValueBox>
                <ValueBox>
                  <Typography variant="body2">{item.win}</Typography>
                </ValueBox>
                <ValueBox>
                  <Typography variant="body2">{item.loss}</Typography>
                </ValueBox>
                <ValueBox>
                  <Typography variant="body2">{item.draw}</Typography>
                </ValueBox>
              </RowBox>
            ))}
          </Box>
        </BodyBox>
      )}
      {loading && <CustomCircularProgress />}
      {!loading &&
        parsedWinAndLoss[4].loss === 0 &&
        parsedWinAndLoss[4].win === 0 && <NoData />}
    </BaseBox>
  );
}
