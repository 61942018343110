import React from 'react';
import { useMediaQuery } from '@material-ui/core';

import Header from '../../../components/Header';
import LeftMenu from '../../../components/LeftMenu';
import BottomMenu from '../../../components/BottomMenu';

import { SafeArea } from './signedLayoutStyles';

export default function SignedLayout({ children }: { children: any }) {
  const matches = useMediaQuery('(min-width:850px)');

  return (
    <>
      <Header />
      {matches ? <LeftMenu /> : <BottomMenu />}
      <SafeArea container>{children}</SafeArea>
    </>
  );
}
