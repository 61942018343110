import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';

import BaseBox from '../_Common/BaseBox';
import {
  BodyBox,
  RowBox,
  ValueBox,
  TextWin,
  TextLoss,
} from '../_Common/CommonComponents';
import NoData from '../_Common/NoData';
import {
  setPercentColor,
  hourToTimezone,
} from '../../../../../utils/functions';
import { useStyles } from './BestHoursStyles';
import CustomCircularProgress from '../_Common/CustomCircularProgress';
import { ApplicationState } from '../../../../../store';

interface Hours {
  hour: number;
  percentOfWin: number;
  qtyWin: number;
  qtyLoss: number;
  color: string;
}

export default function BestHours() {
  const classes = useStyles();
  const [parsedHours, setParsedHours] = useState<Array<Hours>>([]);

  const { loading, boxes } = useSelector(
    (state: ApplicationState) => state.boxes
  );

  useEffect(() => {
    if (boxes.BestHours) {
      const parsedData = boxes.BestHours.map((item: any) => {
        return {
          ...item,
          hour: `${`0${hourToTimezone(item.hour)}`.slice(
            -2
          )} - ${`0${hourToTimezone(item.hour) * 1 + 1}`.slice(-2)}h`,
          color: setPercentColor(item.percentOfWin),
        };
      });

      setParsedHours(parsedData.slice(0, 5));
    }
  }, [boxes.BestHours]);

  return (
    <BaseBox title="Melhores horários">
      {!loading && parsedHours.length > 0 && (
        <BodyBox>
          <Box>
            <RowBox>
              <Typography className={classes.titles} variant="body2">
                %
              </Typography>
              <TextWin className={classes.titles} variant="body2">
                win
              </TextWin>
              <TextLoss className={classes.titles} variant="body2">
                loss
              </TextLoss>
            </RowBox>
            {parsedHours.map(item => (
              <RowBox key={item.hour}>
                <Box style={{ background: 'transparent' }}>
                  <Typography className={classes.hours} variant="body2">
                    {item.hour}
                  </Typography>
                </Box>
                <ValueBox>
                  <Typography
                    variant="body2"
                    style={{ color: `${item.color}` }}
                  >
                    {item.percentOfWin}%
                  </Typography>
                </ValueBox>
                <ValueBox>
                  <Typography variant="body2">{item.qtyWin}</Typography>
                </ValueBox>
                <ValueBox>
                  <Typography variant="body2">{item.qtyLoss}</Typography>
                </ValueBox>
              </RowBox>
            ))}
          </Box>
        </BodyBox>
      )}
      {loading && <CustomCircularProgress />}
      {!loading && parsedHours.length === 0 && <NoData />}
    </BaseBox>
  );
}
