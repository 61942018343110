import React from 'react';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { BodyBox } from '../CommonComponents';

export const useStyles = makeStyles({
  bodyBox: {
    alignItems: 'center',
    height: '226px',
    flexDirection: 'column',
  },
  text: {
    width: '100%',
    marginTop: '11px',
  },
});

export default function NoDataComponent() {
  const classes = useStyles();
  return (
    <BodyBox className={classes.bodyBox}>
      <SentimentVeryDissatisfiedIcon fontSize="large" />
      <Typography component="div" className={classes.text}>
        <Box
          textAlign="center"
          fontWeight="fontWeightLight"
          lineHeight={1.5}
          mb={1}
        >
          Não há dados suficentes para este componente.
          <br />
          Adicione mais trades para visualizar os dados.
        </Box>
      </Typography>
    </BodyBox>
  );
}
