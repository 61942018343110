import React from 'react';
import {
  Container,
  useMediaQuery
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import {
  Logo,
  MenuRight,
  HeaderContainer,
  useStyles,
} from './HeaderStyles';

import logoimage from  '../../../../files/images/logo.png'

export default function Header() {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:435px)');

  return (
    <HeaderContainer>
      <Container className={classes.container}>
        <Logo style={ !matches ? { justifyContent: 'center' } : {}}>
          <img alt="logo" src={logoimage} style={{ width: '220px' }} />
        </Logo>
        {matches && (
        <MenuRight>
          {window.location.pathname !== '/login' && (
          <Link to="/login" className={classes.button}>
            Entrar
          </Link>)}
          {window.location.pathname !== '/register' && (
          <Link to="/register" className={classes.button}>
            Registrar
          </Link>)}
        </MenuRight>
        )}
      </Container>
    </HeaderContainer>
  );
}
