export const HeaderTypes = {
  HEADER_REQUEST: '@header/HEADER_REQUEST',
  HEADER_SUCCESS: '@header/HEADER_SUCCESS',
  HEADER_ADD: '@header/HEADER_ADD',
  HEADER_FAILURE: '@header/HEADER_FAILURE',
};

export interface HeaderState {
  balance: number;
  loading: boolean;
  error: boolean;
}
