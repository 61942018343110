import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';

import BaseBox from '../_Common/BaseBox';
import {
  BodyBox,
  RowBox,
  ValueBox,
  TextWin,
  TextLoss,
} from '../_Common/CommonComponents';
import NoData from '../_Common/NoData';
import { setPercentColor } from '../../../../../utils/functions';
import { useStyles } from './AssetsMostSucessfulStyles';
import CustomCircularProgress from '../_Common/CustomCircularProgress';
import { ApplicationState } from '../../../../../store';

interface Assets {
  asset: string;
  percentOfWin: number;
  qtyWin: number;
  qtyLoss: number;
  color: string;
}

export default function AssetsMostSucessful() {
  const classes = useStyles();
  const [parsedAssets, setParsedAssets] = useState<Array<Assets>>([]);

  const { loading, boxes } = useSelector(
    (state: ApplicationState) => state.boxes
  );

  useEffect(() => {
    if (boxes.AssetsMostSucessful) {
      const parsedData = boxes.AssetsMostSucessful.map((item: any) => {
        return {
          ...item,
          color: setPercentColor(item.percentOfWin),
        };
      });

      setParsedAssets(parsedData.slice(0, 5));
    }
  }, [boxes.AssetsMostSucessful]);

  function correctOTC(asset: String) {
    const arrayString = asset.split(' ');
    if (arrayString.length === 1) {
      return <Typography variant="body2">{arrayString[0]}</Typography>;
    }
    return (
      <Typography variant="body2">
        {arrayString[0]}
        {<span className={classes.otc}>{arrayString[1]}</span>}
      </Typography>
    );
  }

  return (
    <BaseBox title="Ativos com mais acertos">
      {!loading && parsedAssets.length !== 0 && (
        <BodyBox>
          <Box>
            <RowBox>
              <Typography className={classes.titles} variant="body2">
                %
              </Typography>
              <TextWin className={classes.titles} variant="body2">
                win
              </TextWin>
              <TextLoss className={classes.titles} variant="body2">
                loss
              </TextLoss>
            </RowBox>
            {parsedAssets.map(item => (
              <RowBox key={item.asset}>
                <Box className={classes.divOtc}>{correctOTC(item.asset)}</Box>
                <ValueBox>
                  <Typography
                    style={{ color: `${item.color}` }}
                    variant="body2"
                  >
                    {item.percentOfWin}%
                  </Typography>
                </ValueBox>
                <ValueBox>
                  <Typography variant="body2">{item.qtyWin}</Typography>
                </ValueBox>
                <ValueBox>
                  <Typography variant="body2">{item.qtyLoss}</Typography>
                </ValueBox>
              </RowBox>
            ))}
          </Box>
        </BodyBox>
      )}
      {loading && <CustomCircularProgress />}
      {!loading && parsedAssets.length === 0 && <NoData />}
    </BaseBox>
  );
}
