export const BoxesTypes = {
  BOXES_REQUEST: '@boxes/BOXES_REQUEST',
  BOXES_SUCCESS: '@boxes/BOXES_SUCCESS',
  BOXES_FAILURE: '@boxes/BOXES_FAILURE',
  BOXES_SETPENDING: '@boxes/BOXES_SETPENDING',
};

interface AssetsMostSucessfulInterface {
  asset: string;
  percentOfWin: number;
  qtyWin: number;
  qtyLoss: number;
  color: string;
}

interface TradeBestAndWorseTrade {
  date: string;
  asset: string;
  instrument: string;
  investment: number | string;
  result: number | string;
}

interface BestAndWorseTradeInterface {
  bestTrade: TradeBestAndWorseTrade;
  worseTrade: TradeBestAndWorseTrade;
}

interface BestDaysOfWeekInterface {
  dayOfWeek: number;
  qtyWin: number;
  qtyLoss: number;
  percentOfWin: number;
  color: string;
}

interface BestHoursInterface {
  hour: number;
  percentOfWin: number;
  qtyWin: number;
  qtyLoss: number;
  color: string;
}

interface Config {
  tradeInWeekend: boolean;
  goalOfMonth: number;
  currency: string;
}

interface GoalsInterface {
  config: Config;
  progressToday: number;
  progressWeek: number;
  progressMonth: number;
}

interface LastTradesInterface {
  date: string;
  asset: string;
  instrument: string;
  result: number;
  formattedResult: number;
  color: string;
}

export interface MonthlyTracking {
  labels: string[];
  values: number[];
}

export interface ProfitAndLossObj {
  profit: number | string;
  loss: number | string;
  total: number | string;
  totalColor: string;
}

export interface ProfitAndLossInterface {
  today: ProfitAndLossObj;
  week: ProfitAndLossObj;
  month: ProfitAndLossObj;
  year: ProfitAndLossObj;
  allTime: ProfitAndLossObj;
}

export interface WinAndLossObj {
  win: number;
  loss: number;
  draw: number;
  percent: number;
  percentColor: string;
  name: string;
}

export interface WinAndLossInterface {
  today: WinAndLossObj;
  week: WinAndLossObj;
  month: WinAndLossObj;
  year: WinAndLossObj;
  allTime: WinAndLossObj;
}

export interface BoxesInterface {
  AssetsMostSucessful: Array<AssetsMostSucessfulInterface>;
  BestAndWorseTrade: BestAndWorseTradeInterface;
  BestDaysOfWeek: Array<BestDaysOfWeekInterface>;
  BestHours: Array<BestHoursInterface>;
  Goals: GoalsInterface;
  LastTrades: Array<LastTradesInterface>;
  MonthlyTracking: MonthlyTracking;
  ProfitAndLoss: ProfitAndLossInterface;
  WinAndLoss: WinAndLossInterface;
}

export interface BoxesState {
  boxes: BoxesInterface;
  loading: boolean;
  error: boolean;
  pendingRequest: boolean;
}
