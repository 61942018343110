import { styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Button } from '@material-ui/core';


export const Container = styled(Box)({
  width: '100vw',
  height: '100vh',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: '7rem',
  padding: '0 2rem',
  '@media screen and (max-width: 1050px)': {
    gridGap: '5rem',
  },
  '@media screen and (max-width: 900px)': {
    gridTemplateColumns: '1fr',
  },
  '& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
    transition: 'background-color 5000s ease-in-out 0s',
  },
  '& input:-webkit-autofill': {
    WebkitTextFillColor: '#ffffffe0 !important',
    WebkitFontSizeDelta: '1.2rem !important',
  },
});

export const BackgroundWave = styled('img')({
  position: 'fixed',
  bottom: '0',
  left: '0',
  height: '100%',
  '@media screen and (max-width: 900px)': {
    display: 'none',
  },
});

export const LeftImg = styled(Box)({
  display: 'flex',

  justifyContent: 'flex-end',
  alignItems: 'center',
  '& img': {
    width: '500px',
  },
  '@media screen and (max-width: 1000px)': {
    '& img': {
      width: '400px',
    },
  },
  '@media screen and (max-width: 900px)': {
    display: 'none',
  },
});

export const RightContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '& h4': {
    marginBottom: '5px'
  },
  '& p': {
    textAlign: 'left',
    marginBottom: '30px'
  },
});
export const WhatsAppButton = styled(Button)({
  background: '#25d366',
  color: '#fff',
  '&:hover': {
    background: '#1fb356'
  }
})