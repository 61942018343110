import {
  makeStyles,
  Box,
  Grid,
  styled,
  withStyles,
  Tooltip,
} from '@material-ui/core';
import theme from '../../../styles/theme';

export const Container = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '90%',
  marginTop: '20px',
  background: theme.colors.backgroundLight,
  borderRadius: '4px',
  position: 'relative',
});

export const BoxAvatar = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  marginTop: '20px',
});

export const BoxInputs = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  marginTop: '20px',
});

export const useStyles = makeStyles({
  avatar: {
    color: '#fff9',
    width: '60px',
    height: '60px',
    fontSize: '30px',
  },
  logOut: {
    position: 'absolute',
    right: '15px',
  },
  divider: {
    width: '100%',
    marginTop: '25px',
  },
  phoneInput: {
    marginTop: '20px',
  },
  emailInput: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  titlePassword: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  passwordInputs: {
    marginBottom: '20px',
  },
  formControl: {
    width: '100%',
  },
});
export const ExitTooltip = withStyles({
  tooltip: {
    fontSize: '11px',
  },
})(Tooltip);
