import { Reducer } from 'redux';
import produce from 'immer';

import { UserState, UserTypes } from './types';
import { AuthTypes } from '../auth/types';

const INITIAL_STATE: UserState = {
  user: {
    userId: '',
    name: '',
    phone: '',
    email: '',
    isActive: false,
    createdAt: '',
    config: {
      tradeInWeekend: true,
      goalOfMonth: 500,
      currency: 'BRL',
    },
  },
};

const auth: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.SIGN_IN_SUCCESS:
      return produce(state, draft => {
        draft.user = action.payload.user;
      });
    case UserTypes.USER_CHANGE:
      return produce(state, draft => {
        draft.user.name = action.payload.name;
        draft.user.phone = action.payload.phone;
        draft.user.config.goalOfMonth = action.payload.goal;
        draft.user.config.currency = action.payload.currency;
      });
    case AuthTypes.LOGOUT: {
      return produce(state, draft => {
        draft.user = INITIAL_STATE.user;
      });
    }
    default:
      return state;
  }
};

export default auth;
