import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { NavLink, Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SchoolIcon from '@material-ui/icons/School';
import ViewListIcon from '@material-ui/icons/ViewList';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import { ApplicationState } from '../../store';
import {
  Container,
  TopIcons,
  BottomIcons,
  IconTooltip,
  SeeTutorials,
  useStyles,
} from './LeftMenuStyles';
import AddButton from './AddTrade';

export default function LeftMenu() {
  const classes = useStyles();

  const boxesState = useSelector((state: ApplicationState) => state.boxes);

  return (
    <Container className={classes.container}>
      <TopIcons>
        <IconTooltip title="Início" placement="right">
          <Link to="/">
            <IconButton>
              <NavLink
                style={{ height: '24px', color: '#fff' }}
                to="/"
                exact
                activeStyle={{ color: '#ffee58' }}
              >
                <HomeIcon style={{ color: 'inhehit' }} />
              </NavLink>
            </IconButton>
          </Link>
        </IconTooltip>
        <IconTooltip title="Histórico de trades" placement="right">
          <Link to="/history">
            <IconButton>
              <NavLink
                style={{ height: '24px', color: '#fff' }}
                to="/history"
                activeStyle={{ color: '#ffee58' }}
              >
                <ViewListIcon style={{ color: 'inhehit' }} />
              </NavLink>
            </IconButton>
          </Link>
        </IconTooltip>
        <IconTooltip title="Depósitos e retiradas" placement="right">
          <Link to="/depositsAndWithdraws">
            <IconButton>
              <NavLink
                style={{ height: '24px', color: '#fff' }}
                to="/depositsAndWithdraws"
                activeStyle={{ color: '#ffee58' }}
              >
                <SwapHorizontalCircleIcon style={{ color: 'inhehit' }} />
              </NavLink>
            </IconButton>
          </Link>
        </IconTooltip>
        <AddButton />
      </TopIcons>

      <BottomIcons>
        <hr className={classes.hr} />
        {boxesState.boxes.LastTrades &&
          boxesState.boxes.LastTrades.length === 0 &&
          window.location.pathname !== '/tutorials' && (
            <SeeTutorials>
              <ArrowLeftIcon className={classes.arrowTutorial} />
              <h4>Não sabe por onde começar?</h4>
              <p>Veja nosso tutorial inicial e comece agora mesmo!</p>
            </SeeTutorials>
          )}
        <IconTooltip title="Tutoriais" placement="right">
          <IconButton>
            <NavLink
              style={{ height: '24px', color: '#fff' }}
              to="/tutorials"
              activeStyle={{ color: '#ffee58' }}
            >
              <SchoolIcon />
            </NavLink>
          </IconButton>
        </IconTooltip>
        <IconTooltip title="Suporte" placement="right">
          <a
            rel="noopener noreferrer"
            href="https://api.whatsapp.com/send?phone=5548998589424"
            target="_blank"
          >
            <IconButton>
              <WhatsAppIcon />
            </IconButton>
          </a>
        </IconTooltip>
      </BottomIcons>
    </Container>
  );
}
