import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  TextField,
  Divider,
  InputAdornment,
  IconButton,
  FormControl,
  FilledInput,
  InputLabel,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { toast } from 'react-toastify';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { ApplicationState } from '../../../store';
import api from '../../../services/api';
import { changeUser } from '../../../store/modules/user/actions';
import { useStyles, Container, BoxAvatar, BoxInputs } from './AccountStyles';
import { logOut } from '../../../store/modules/auth/actions';

export default function TradesHistory() {
  const dispatch = useDispatch();
  const userState = useSelector((state: ApplicationState) => state.user);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(userState.user.name);
  const [phone, setPhone] = useState(userState.user.phone);
  const [goal, setGoal] = useState(userState.user.config.goalOfMonth);
  const [currency, setCurrency] = useState(userState.user.config.currency);

  const [currentPassword, setCurrentPassword] = useState({
    value: '',
    showPassword: false,
  });
  const [newPassword, setNewPassword] = useState({
    value: '',
    showPassword: false,
  });
  const [confirmNewPassword, setconfirmNewPassword] = useState({
    value: '',
    showPassword: false,
  });

  function handleSubmit() {
    if (currentPassword.value) {
      setLoading(true);
      api
        .put('user', {
          name,
          phone,
          oldPassword: currentPassword.value,
          password: newPassword.value,
          confirmPassword: confirmNewPassword.value,
          goalOfMonth: goal,
          currency,
        })
        .then(res => {
          setLoading(false);
          dispatch(changeUser(name, phone, goal, currency));
          toast.success(res.data.message);
        })
        .catch(err => {
          setLoading(false);
          toast.error(err.response.data.error);
        });
    } else {
      setLoading(true);
      api
        .put('user', {
          name,
          phone,
          goalOfMonth: goal,
          currency,
        })
        .then(res => {
          setLoading(false);
          dispatch(changeUser(name, phone, goal, currency));
          toast.success(res.data.message);
        })
        .catch(err => {
          setLoading(false);
          toast.error(err.response.data.error);
        });
    }
    setCurrentPassword({
      value: '',
      showPassword: false,
    });
    setNewPassword({
      value: '',
      showPassword: false,
    });
    setconfirmNewPassword({
      value: '',
      showPassword: false,
    });
  }
  function handleLogOut() {
    dispatch(logOut());
  }

  const classes = useStyles();
  return (
    <>
      <Container container>
        <div style={{ display: 'flex', marginTop: '15px' }}>
          <Typography variant="h5">Minha conta</Typography>
          <Button className={classes.logOut} onClick={() => handleLogOut()}>
            Sair
            <ExitToAppIcon style={{ fill: '#fff', marginLeft: '5px' }} />
          </Button>
        </div>
        <BoxAvatar>
          {/* <Avatar className={classes.avatar}>{initials}</Avatar> */}
        </BoxAvatar>
        <BoxInputs xs={11} sm={11} md={9} lg={9} item>
          <TextField
            label="Nome"
            id="account-name"
            defaultValue={name}
            variant="filled"
            fullWidth
            onChange={e => setName(e.target.value)}
          />
          <TextField
            label="Telefone"
            className={classes.phoneInput}
            id="account-phone"
            defaultValue={phone}
            variant="filled"
            fullWidth
            onChange={e => setPhone(e.target.value)}
          />
          <TextField
            className={classes.emailInput}
            label="Email"
            id="account-email"
            defaultValue={userState.user.email}
            variant="filled"
            fullWidth
            disabled
          />
          <Grid style={{ width: '100%' }}>
            <TextField
              style={{ width: '40%', marginRight: '10%', marginLeft: '5%' }}
              label="Meta mensal"
              id="account-goal"
              defaultValue={goal}
              variant="filled"
              onChange={e => setGoal(parseInt(e.target.value))} // eslint-disable-line
            />
            <FormControl style={{ width: '40%' }}>
              <InputLabel variant="filled" id="account-currency-select">
                Tipo
              </InputLabel>
              <Select
                labelId="account-currency-select"
                id="demo-simple-select"
                variant="filled"
                value={currency}
                onChange={(e: any) => setCurrency(e.target.value)}
              >
                <MenuItem value={'BRL'}>BRL</MenuItem>
                <MenuItem value={'USD'}>USD</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Divider light className={classes.divider} />
          <Typography className={classes.titlePassword} variant="body1">
            Alterar senha
          </Typography>
          <FormControl className={classes.formControl} variant="filled">
            <InputLabel htmlFor="account-current-passwod">
              Senha atual
            </InputLabel>
            <FilledInput
              id="account-current-passwod"
              type={currentPassword.showPassword ? 'text' : 'password'}
              className={classes.passwordInputs}
              value={currentPassword.value}
              onChange={e =>
                setCurrentPassword({
                  ...currentPassword,
                  value: e.target.value,
                })
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setCurrentPassword({
                        ...currentPassword,
                        showPassword: !currentPassword.showPassword,
                      })
                    }
                    onMouseDown={e => e.preventDefault()}
                  >
                    {currentPassword.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="filled">
            <InputLabel htmlFor="account-new-password">Nova senha</InputLabel>
            <FilledInput
              id="account-new-password"
              type={newPassword.showPassword ? 'text' : 'password'}
              className={classes.passwordInputs}
              value={newPassword.value}
              onChange={e =>
                setNewPassword({
                  ...newPassword,
                  value: e.target.value,
                })
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setNewPassword({
                        ...newPassword,
                        showPassword: !newPassword.showPassword,
                      })
                    }
                    onMouseDown={e => e.preventDefault()}
                  >
                    {currentPassword.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl className={classes.formControl} variant="filled">
            <InputLabel htmlFor="account-confirm-new-password">
              Confirme a nova senha
            </InputLabel>
            <FilledInput
              id="account-confirm-new-password"
              type={confirmNewPassword.showPassword ? 'text' : 'password'}
              className={classes.passwordInputs}
              value={confirmNewPassword.value}
              onChange={e =>
                setconfirmNewPassword({
                  ...confirmNewPassword,
                  value: e.target.value,
                })
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setconfirmNewPassword({
                        ...confirmNewPassword,
                        showPassword: !confirmNewPassword.showPassword,
                      })
                    }
                    onMouseDown={e => e.preventDefault()}
                  >
                    {currentPassword.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button
            style={{
              marginTop: '20px',
              width: '100%',
              padding: '10px',
              marginBottom: '30px',
            }}
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
            component="span"
            disabled={!!loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Atualizar dados'}
          </Button>
        </BoxInputs>
      </Container>
    </>
  );
}
