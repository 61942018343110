import React, { useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import { GenerateNewToken } from './GenerateNewTokenDialogStyles';

export default function GenerateNewTokenDialog() {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');

  function handleGenerateNewTokenSubmit(): void {
    if (email) {
      api
        .post('resendEmailToken', {
          email,
        })
        .then(res => {
          handleClose();
          toast.success(res.data.message);
        })
        .catch(err => {
          toast.error(err.response.data.error);
        });
    } else {
      toast.error('Digite um email');
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <GenerateNewToken>
        <button type="button" onClick={handleClickOpen}>
          Gerar novo token
        </button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Gerar novo token</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Digite seu email que enviaremos um novo email contendo o link com
              token para validar seu email.
            </DialogContentText>
            <TextField
              autoFocus
              value={email}
              onChange={e => setEmail(e.target.value)}
              margin="dense"
              id="forgot-password-email"
              label="Email"
              type="email"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} style={{ color: '#ffffffb3' }}>
              Cancelar
            </Button>
            <Button onClick={handleGenerateNewTokenSubmit} color="primary">
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </GenerateNewToken>
    </>
  );
}
