import { Box, Grid } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import theme from '../../../../../../styles/theme';

export const GridItems = styled(Grid)({
  padding: '4px',
});

export const Container = styled(Box)({
  paddingBottom: '15px',
  borderRadius: theme.borderRadius,
  background: theme.colors.backgroundLight,
  overflow: 'hidden',
});

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#23284B',
  height: '30px',
});

export const Body = styled(Box)({
  minHeight: '170px',
});
