import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '../../../store';
import { boxesRequest } from '../../../store/modules/boxes/actions';
import AssetsMostSucessful from './Boxes/AssetsMostSucessful';
import BestHours from './Boxes/BestHours';
import Goals from './Boxes/Goals';
import BestDaysOfWeek from './Boxes/BestDaysOfWeek';
import BestAndWorseTrade from './Boxes/BestAndWorseTrade';
import LastTrades from './Boxes/LastTrades';
import MonthlyTracking from './Boxes/MonthlyTracking';
import ProfitAndLoss from './Boxes/ProfitAndLoss';
import WinAndLoss from './Boxes/WinAndLoss';

export default function Home() {
  const dispatch = useDispatch();
  const boxesState = useSelector((state: ApplicationState) => state.boxes);

  useEffect(() => {
    if (
      (Object.entries(boxesState.boxes).length === 0 &&
      boxesState.boxes.constructor === Object) || boxesState.pendingRequest
    ) {
      dispatch(boxesRequest());
    }
  }, []); // eslint-disable-line

  return (
    <>
      <MonthlyTracking />
      <ProfitAndLoss />
      <Goals />
      <WinAndLoss />
      <AssetsMostSucessful />
      <BestHours />
      <LastTrades />
      <BestAndWorseTrade />
      <BestDaysOfWeek />
    </>
  );
}
