export const UserTypes = {
  USER_CHANGE: '@user/USER_CHANGE',
};

interface UserConfig {
  tradeInWeekend: boolean;
  goalOfMonth: number;
  currency: string;
}

export interface User {
  userId: string;
  name: string;
  phone: string;
  email: string;
  config: UserConfig;
  isActive: boolean;
  createdAt: string;
}

export interface UserState {
  user: User;
}
