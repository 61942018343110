import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Divider,
  Button,
  CircularProgress,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import LinkIcon from '@material-ui/icons/Link';
import { toast } from 'react-toastify';

import { headerRequest } from '../../../../store/modules/header/actions';
import { boxesRequest } from '../../../../store/modules/boxes/actions';
import api from '../../../../services/api';

import { useStyles } from './UploadCsvStyles';

export default function UploadCsv() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  function handleUpload(event: any) {
    if (event.target.files[0]) {
      const data = new FormData();
      data.append('file', event.target.files[0]);
      setLoading(true);

      api
        .post('trade/csv', data)
        .then(res => {
          setLoading(false);
          dispatch(headerRequest());
          dispatch(boxesRequest());
          toast.success(res.data.message);
        })
        .catch(err => {
          setLoading(false);
          toast.error(err.response.data.error);
        });
    }
  }

  const classes = useStyles();
  return (
    <>
      <Box className={classes.titleBox}>
        <WarningIcon />
        <Typography variant="h6">Atenção!</Typography>
        <WarningIcon />
      </Box>
      <ul className={classes.list}>
        <li>
          <div className="icon">
            <ErrorIcon fontSize="small" />
          </div>
          Somente faça upload do CSV baixado diretamente da iqOption
        </li>
        <li>
          <div className="icon">
            <ErrorIcon fontSize="small" />
          </div>
          Nunca altere o arquivo baixado da iqOption
        </li>
        <li>
          <div className="icon">
            <LinkIcon fontSize="small" />
          </div>
          <a
            href="https://iqoption.com/trading"
            target="_blank"
            rel="noopener noreferrer"
          >
            Clique aqui&nbsp;
          </a>{' '}
          para baixar o .CSV
        </li>
      </ul>
      <Divider />
      <label htmlFor="upload-csv">
        <input
          style={{ display: 'none' }}
          accept=".csv"
          id="upload-csv"
          type="file"
          onChange={e => handleUpload(e)}
        />
        <Button
          style={{ padding: '13px 45px', marginTop: '40px', minWidth: '200px' }}
          variant="contained"
          color="primary"
          component="span"
          disabled={!!loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Fazer upload'}
        </Button>
      </label>
    </>
  );
}
