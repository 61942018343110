import { action } from 'typesafe-actions';
import { HeaderTypes } from './types';

export const headerRequest = () => action(HeaderTypes.HEADER_REQUEST);

export const headerSuccess = (balance: number) =>
  action(HeaderTypes.HEADER_SUCCESS, { balance });

export const headerAdd = (value: any) =>
  action(HeaderTypes.HEADER_ADD, { value });

export const headerFailure = () => action(HeaderTypes.HEADER_FAILURE);
