import React, { useState, CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import {
  Fab,
  Popover,
  Grid,
  Box,
  Typography,
  Tabs,
  Tab,
  AppBar,
  useTheme,
  NoSsr,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  InputAdornment,
  Button,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ValueType } from 'react-select/src/types';
import RSelect from 'react-select';
import { toast } from 'react-toastify';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { pt } from 'date-fns/locale';
import { format } from 'date-fns';

import api from '../../../services/api';
import { headerAdd } from '../../../store/modules/header/actions';
import { boxesRequest } from '../../../store/modules/boxes/actions';
import { correctPricePtBrToEn } from '../../../utils/functions';
import { useStyles, IconTooltip } from './AddTradeStyles';
import AssetAutoCompleteObject from './AssetAutoComplete';
import UploadCsv from './UploadCsv';

export default function AddTrade() {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:600px)');
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  function handleSubmit() {
    if (investment === '') {
      setInvestmentError(true)
    } else {
      setInvestmentError(false)
    }
    if (result === '') {
      setResultError(true)
    } else {
      setResultError(false)
    }
    if (result === '' || (investment === '' && instrument !== 'Forex')) return

    setLoading(true);
      api.post('trade', {
        date,
        instrument,
        // @ts-ignore
        asset: asset.value,
        investment: instrument === 'Forex' ? 0 : correctPricePtBrToEn(investment),
        result: correctPricePtBrToEn(result)
      }).then(res => {
        dispatch(boxesRequest());
        const correctResult = result.replace(/,/g, ".") * 1
        dispatch(headerAdd(correctResult));

        setLoading(false);
        toast.success('Trade criado com sucesso');
      }).catch(err => {
        setLoading(false);
        toast.error(err.response.data.error);
      })
  }

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Tabs

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  // End Tabs

  // SelectAsset

  interface OptionType {
    label: string;
    value: string;
  }

  const theme = useTheme();
  const [asset, setAsset] = React.useState<ValueType<OptionType>>({
    value: "EUR/USD",
    label: "EUR/USD",
  });

  const handleChangeAsset = (newAsset: ValueType<OptionType>) => {
    setAsset(newAsset);
  };

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };
  const classesAsset = AssetAutoCompleteObject.useStylesAsset();

  // End SelectAsset

  // SelectInstrument

  const [instrument, setInstrument] = useState('Binary Options');

  const handleInstrumentChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setInstrument(event.target.value as string);
  };

  // End SelectInstrument

  // DateAndTime

  const [date, setDate] = useState<string>(
    format(new Date(), "yyyy'-'MM'-'dd'T'HH':'mm':'ssxxx")
  );

  const [inputDate, setInputDate] = useState<Date | null>(new Date());

  const handleDateChange = (selectedDate: Date | null) => {
    setInputDate(selectedDate);
    if (selectedDate != null) {
      setDate(format(selectedDate, "yyyy'-'MM'-'dd'T'HH':'mm':'ssxxx"));
    }
  };

  // End DateAndTime

  // Investment and Result

  const [investment, setInvestment] = useState('');
  const [result, setResult] = useState<any>('');
  const [investmentError, setInvestmentError] = useState(false);
  const [resultError, setResultError] = useState(false);


  const handleInvestmentChange = (event: any) => {
    setInvestment(event.target.value);
  };

  const handleResultChange = (event: any) => {
    setResult(event.target.value);
  };

  // End Investment and Result

  const matchesToPopover = useMediaQuery('(min-width:850px)');

  return (
    <>
      <IconTooltip title="Adicionar trade" placement="right">
        <Fab onClick={handleClick} className={classes.add} color="primary">
          <AddIcon />
        </Fab>
      </IconTooltip>
      <Grid xs={12} sm={6} md={6} lg={4} item>
        <Popover
          style={matchesToPopover ? { top: '-70px', left: '39px' } : { top: '-41px', left: '-17px' }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box
            style={{
              width: matches ? '420px' : '300px',
              height: '420px',
              textAlign: 'center',
              backgroundColor: '#343b6b',
              overflowY: matches ? 'hidden' : 'scroll',
            }}
          >
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab label="Manual" {...a11yProps(0)} />
                <Tab label="CSV" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    style={{ width: '160px' }}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Data"
                    value={inputDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <KeyboardTimePicker
                    style={{ width: '160px' }}
                    margin="normal"
                    id="time-picker"
                    label="Hora"
                    ampm={false}
                    value={inputDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <Grid
                container
                justify="space-around"
                style={{ marginTop: matches ? '20px' : '5px' }}
              >
                <FormControl style={{ minWidth: '170px', marginBottom: matches ? '0px' : '15px' }}>
                  <InputLabel id="instrument-select">Tipo</InputLabel>
                    <Select
                      labelId="instrument-select"
                      id="demo-simple-select"
                      value={instrument}
                      onChange={handleInstrumentChange}
                    >
                    <MenuItem value={'Binary Options'}>Opções Binárias</MenuItem>
                    <MenuItem value={'Digital Options'}>Digitais</MenuItem>
                    <MenuItem value={'Forex'}>Forex</MenuItem>
                  </Select>
                </FormControl>
                <div className={classesAsset.root}>
                  <NoSsr>
                    <RSelect
                      classes={classesAsset}
                      styles={selectStyles}
                      inputId="react-select-single-asset"
                      TextFieldProps={{
                        label: 'Ativo',
                        InputLabelProps: {
                          htmlFor: 'react-select-single-asset',
                          shrink: true,
                        },
                      }}
                      placeholder="Selecione o ativo"
                      options={AssetAutoCompleteObject.suggestions}
                      components={AssetAutoCompleteObject.components}
                      value={asset}
                      onChange={handleChangeAsset}
                    />
                  </NoSsr>
                </div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <UploadCsv />
            </TabPanel>
            {
              value === 0 && <> { instrument !== 'Forex' && <TextField
              id="manual-investment-number"
              label="Investimento"
              error={investmentError}
              placeholder="30,00"
              autoComplete="off"
              value={investment}
              onChange={handleInvestmentChange}
              style={{ maxWidth: '170px', marginRight: matches ? '17px' : '0px' }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              />}
              <TextField
                id="manual-result-number"
                label="Resultado"
                type=""
                error={resultError}
                autoComplete="off"
                placeholder="27,00"
                value={result}
                onChange={handleResultChange}
                style={{ maxWidth: '170px', marginTop: matches ? '0px' : '10px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
              <Button
          style={{ marginTop: '40px', width: '86%', padding: '10px', marginBottom: '10px' }}
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          component="span"
          disabled={!!loading}
        >{loading ? <CircularProgress size={24} /> : 'Adicionar'}
        </Button>
              </>
            }
          </Box>
        </Popover>
      </Grid>
    </>
  );
}
