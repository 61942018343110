import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SchoolIcon from '@material-ui/icons/School';
import ViewListIcon from '@material-ui/icons/ViewList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import AddTrade from '../LeftMenu/AddTrade';
import history from '../../services/history';
import { useStyles, SeeTutorials } from './BottomMenuStyles';
import { ApplicationState } from '../../store';

export default function Header() {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const boxesState = useSelector((state: ApplicationState) => state.boxes);

  useEffect(() => {
    switch (window.location.pathname) {
      case '/':
        setValue(0);
        return;
      case '/history':
        setValue(1);
        return;
      case '/depositsAndWithdraws':
        setValue(3);
        return;
      case '/tutorials':
        setValue(4);
        return;
      default:
        setValue(0);
        break;
    }
  }, []);

  function handleMenuChange(newValue: number) {
    switch (newValue) {
      case 0:
        history.push('/');
        return;
      case 1:
        history.push('/history');
        return;
      case 3:
        history.push('/depositsAndWithdraws');
        return;
      case 4:
        history.push('/tutorials');
        return;
      case 5:
        window.open(
          'https://api.whatsapp.com/send?phone=5548998589424',
          '_blank'
        );
        return;
      default:
        history.push('/');
        setValue(0);
        break;
    }
  }

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        if (newValue === 2) return;
        setValue(newValue === 5 ? value : newValue);
        handleMenuChange(newValue);
      }}
      className={classes.root}
    >
      <BottomNavigationAction label="Início" icon={<HomeIcon />} />
      <BottomNavigationAction label="Histórico" icon={<ViewListIcon />} />
      <BottomNavigationAction
        style={{ paddingTop: '5px' }}
        icon={<AddTrade />}
      />
      <BottomNavigationAction
        label="Depo/Reti"
        icon={<SwapHorizontalCircleIcon />}
      />
      <BottomNavigationAction label="Tutoriais" icon={<SchoolIcon />} />
      <BottomNavigationAction label="Suporte" icon={<WhatsAppIcon />} />
      {boxesState.boxes.LastTrades &&
        boxesState.boxes.LastTrades.length === 0 &&
        window.location.pathname !== '/tutorials' && (
          <SeeTutorials>
            <ArrowDropDownIcon className={classes.arrowTutorial} />
            <h4>Não sabe por onde começar?</h4>
            <p>Veja nosso tutorial inicial e comece agora mesmo!</p>
          </SeeTutorials>
        )}
    </BottomNavigation>
  );
}
