import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';
import Chart from 'chart.js';

import { ApplicationState } from '../../../../../store';
import { MonthlyTracking } from '../../../../../store/modules/boxes/types';
import BaseBox from '../_Common/BaseBox';
import NoData from '../_Common/NoData';
import CustomCircularProgress from '../_Common/CustomCircularProgress';
import { Container } from './MonthlyTrackingStyles';

export default function BestAndWorseTrade() {
  const { loading, boxes } = useSelector(
    (state: ApplicationState) => state.boxes
  );
  const initialState: MonthlyTracking = {
    labels: [],
    values: [],
  };
  const [parsedData, setParsedData] = useState<MonthlyTracking>(initialState);
  const theme = useTheme();

  Chart.defaults.global.defaultFontColor = '#fff';

  const options = {
    tooltips: {
      backgroundColor: '#1e2141ed',
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: '#ffffff24',
            zeroLineColor: '#ffffff24',
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: '#ffffff24',
            zeroLineColor: '#ffffff73',
          },
        },
      ],
    },
    maintainAspectRatio: false
  };

  let data = {};

  if (boxes.MonthlyTracking) {
    data = {
      labels: boxes.MonthlyTracking.labels,
      datasets: [
        {
          label: 'Total R$',
          fill: false,
          lineTension: 0,
          borderColor: theme.colors.primary,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#dccd47',
          pointBackgroundColor: '#dccd47',
          pointBorderWidth: 5,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#dccd47',
          pointHoverBorderColor: '#dccd47',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: boxes.MonthlyTracking.values,
        },
      ],
    };
  }

  useEffect(() => {
    if (boxes.MonthlyTracking) {
      setParsedData(boxes.MonthlyTracking);
    }
  }, [boxes.MonthlyTracking]);

  return (
    <BaseBox title="Acompanhamento mensal">
      {!loading && parsedData.values.length > 0 && (
        <Container>
          <Line options={options} data={data} />
        </Container>
      )}
      {loading && <CustomCircularProgress />}
      {!loading && parsedData.values.length === 0 && <NoData />}
    </BaseBox>
  );
}
