import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  titleProfit: {
    width: '60px',
    marginRight: '48px',
    textAlign: 'center',
  },
  titleLoss: {
    width: '60px',
    marginRight: '47px',
    textAlign: 'center',
  },
  titleTotal: {
    width: '60px',
    marginRight: '19px',
    textAlign: 'center',
  },
  boxTitles: {
    background: 'transparent !important',
  },
  valueBox: {
    width: '100px',
  },
});
