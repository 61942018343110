import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  titles: {
    width: '60px',
    textAlign: 'center',
    '&:nth-child(-n+2)': {
      marginRight: '7px',
    },
  },
  otc: {
    position: 'absolute',
    top: '-8px',
    right: '0px',
    fontSize: '9px',
  },
  divOtc: {
    position: 'relative',
    background: 'transparent !important',
  },
});
