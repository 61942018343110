import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import Login from '../pages/Public/Login';
import Register from '../pages/Public/Register';
import ValidateEmail from '../pages/Public/ValidateEmail';
import ForgotPassword from '../pages/Public/ForgotPassword';
import Home from '../pages/Private/Home';
import Account from '../pages/Private/Account';
import TradesHistory from '../pages/Private/TradesHistory';
import DepositsAndWithdraws from '../pages/Private/DepositsAndWithdraws';
import Tutorials from '../pages/Private/Tutorials';
import TrialExpired from '../pages/Public/TrialExpired';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact isPrivate component={Home} />
      <Route path="/account" isPrivate component={Account} />
      <Route path="/history" isPrivate component={TradesHistory} />
      <Route
        path="/depositsAndWithdraws"
        isPrivate
        component={DepositsAndWithdraws}
      />
      <Route path="/tutorials" isPrivate component={Tutorials} />

      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/trialExpired" component={TrialExpired} />
      <Route path="/validateEmail/:token" component={ValidateEmail} />
      <Route path="/forgotPassword/:token" component={ForgotPassword} />
      <Route component={Home} />
    </Switch>
  );
}
