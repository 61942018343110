import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  titles: {
    width: '60px',
    textAlign: 'center',
    '&:last-child': {
      marginRight: '33px',
    },
    '&:nth-child(1)': {
      marginRight: '71px',
    },
  },
  otc: {
    position: 'absolute',
    top: '-9px',
    right: '-11px',
    fontSize: '9px',
  },
  divOtc: {
    position: 'relative',
    background: 'transparent !important',
  },
  largeValueBox: {
    width: '125px',
  },
});
