import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box, useTheme } from '@material-ui/core';

import BaseBox from '../_Common/BaseBox';
import {
  BodyBox,
  RowBox,
  ValueBox,
  TextWin,
  TextLoss,
} from '../_Common/CommonComponents';
import {
  formatPrice,
  dateToTimezone,
  correctInstrument,
} from '../../../../../utils/functions';
import NoData from '../_Common/NoData';
import { useStyles } from './BestAndWorseTradeStyles';
import CustomCircularProgress from '../_Common/CustomCircularProgress';
import { ApplicationState } from '../../../../../store';

interface Trade {
  date: string;
  asset: string;
  instrument: string;
  investment: number | string;
  result: number | string;
}

interface BestAndWorseTradeInterface {
  bestTrade: Trade;
  worseTrade: Trade;
}

const initialState = {
  bestTrade: {
    date: 'empty',
    asset: '',
    instrument: '',
    investment: 0,
    result: 0,
  },
  worseTrade: {
    date: 'empty',
    asset: '',
    instrument: '',
    investment: 0,
    result: -0,
  },
};

export default function BestAndWorseTrade() {
  const classes = useStyles();
  const [parsedTrades, setParsedTrades] = useState<BestAndWorseTradeInterface>(
    initialState
  );

  const { loading, boxes } = useSelector(
    (state: ApplicationState) => state.boxes
  );

  useEffect(() => {
    if (boxes.BestAndWorseTrade) {
      if (boxes.BestAndWorseTrade.worseTrade.date) {
        const parsedData: BestAndWorseTradeInterface = {
          bestTrade: {
            date: dateToTimezone(boxes.BestAndWorseTrade.bestTrade.date),
            asset: boxes.BestAndWorseTrade.bestTrade.asset,
            instrument: correctInstrument(
              boxes.BestAndWorseTrade.bestTrade.instrument
            ),
            investment: formatPrice(
              boxes.BestAndWorseTrade.bestTrade.investment
            ),
            result: formatPrice(boxes.BestAndWorseTrade.bestTrade.result),
          },
          worseTrade: {
            date: dateToTimezone(boxes.BestAndWorseTrade.worseTrade.date),
            asset: boxes.BestAndWorseTrade.worseTrade.asset,
            instrument: correctInstrument(
              boxes.BestAndWorseTrade.worseTrade.instrument
            ),
            investment: formatPrice(
              boxes.BestAndWorseTrade.worseTrade.investment
            ),
            result: formatPrice(boxes.BestAndWorseTrade.worseTrade.result),
          },
        };
        setParsedTrades(parsedData);
      }
    }
  }, [boxes.BestAndWorseTrade]);

  const theme = useTheme();

  return (
    <BaseBox title="Melhor/Pior trade">
      {!loading && parsedTrades.bestTrade.date !== 'empty' && (
        <BodyBox>
          <Box>
            <RowBox>
              <TextWin className={classes.titles} variant="body2">
                Melhor
              </TextWin>
              <TextLoss className={classes.titles} variant="body2">
                Pior
              </TextLoss>
            </RowBox>
            <RowBox>
              <Box className={classes.divOtc}>Data</Box>
              <ValueBox className={classes.largeValueBox}>
                <Typography variant="caption">
                  {parsedTrades.bestTrade.date}
                </Typography>
              </ValueBox>
              <ValueBox className={classes.largeValueBox}>
                <Typography variant="caption">
                  {parsedTrades.worseTrade.date}
                </Typography>
              </ValueBox>
            </RowBox>
            <RowBox>
              <Box className={classes.divOtc}>Tipo</Box>
              <ValueBox className={classes.largeValueBox}>
                <Typography variant="body2">
                  {parsedTrades.bestTrade.instrument}
                </Typography>
              </ValueBox>
              <ValueBox className={classes.largeValueBox}>
                <Typography variant="body2">
                  {parsedTrades.worseTrade.instrument}
                </Typography>
              </ValueBox>
            </RowBox>
            <RowBox>
              <Box className={classes.divOtc}>Ativo</Box>
              <ValueBox className={classes.largeValueBox}>
                <Typography variant="body2">
                  {parsedTrades.bestTrade.asset}
                </Typography>
              </ValueBox>
              <ValueBox className={classes.largeValueBox}>
                <Typography variant="body2">
                  {parsedTrades.worseTrade.asset}
                </Typography>
              </ValueBox>
            </RowBox>
            <RowBox>
              <Box className={classes.divOtc}>Investimento</Box>
              <ValueBox className={classes.largeValueBox}>
                <Typography variant="body2">
                  {parsedTrades.bestTrade.investment}
                </Typography>
              </ValueBox>
              <ValueBox className={classes.largeValueBox}>
                <Typography variant="body2">
                  {parsedTrades.worseTrade.investment}
                </Typography>
              </ValueBox>
            </RowBox>
            <RowBox>
              <Box className={classes.divOtc}>Resultado</Box>
              <ValueBox className={classes.largeValueBox}>
                <Typography
                  style={{ color: `${theme.colors.win}` }}
                  variant="body2"
                >
                  {parsedTrades.bestTrade.result}
                </Typography>
              </ValueBox>
              <ValueBox className={classes.largeValueBox}>
                <Typography
                  style={{ color: `${theme.colors.loss}` }}
                  variant="body2"
                >
                  {parsedTrades.worseTrade.result}
                </Typography>
              </ValueBox>
            </RowBox>
          </Box>
        </BodyBox>
      )}
      {loading && <CustomCircularProgress />}
      {!loading && parsedTrades.bestTrade.date === 'empty' && <NoData />}
    </BaseBox>
  );
}
