import { Reducer } from 'redux';
import produce from 'immer';
import { HeaderState, HeaderTypes } from './types';

const INITIAL_STATE: HeaderState = {
  balance: 0,
  loading: false,
  error: false,
};

const boxes: Reducer<HeaderState> = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case HeaderTypes.HEADER_REQUEST: {
        draft.loading = true;
        break;
      }
      case HeaderTypes.HEADER_SUCCESS: {
        draft.balance = action.payload.balance;
        draft.loading = false;
        break;
      }
      case HeaderTypes.HEADER_ADD: {
        draft.balance = action.payload.value + draft.balance;
        break;
      }
      case HeaderTypes.HEADER_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      default:
    }
  });
};

export default boxes;
