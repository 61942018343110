import { makeStyles } from '@material-ui/core';
import theme from '../../../../../styles/theme';

export const useStyles = makeStyles({
  boxLines: {
    '@media (max-width: 756px)': {
      minWidth: '332px',
    },
    opacity: 1,
  },
  titles: {
    width: '60px',
    textAlign: 'center',
  },
  largeValueBox: {
    width: '125px',
  },
  otc: {
    position: 'absolute',
    top: '-6px',
    right: '1px',
    fontSize: '9px',
    padding: '2px 2px 0px 3px',
    backgroundColor: theme.colors.background,
    borderRadius: '9px',
    borderBottomRightRadius: '0px',
  },
  divOtc: {
    position: 'relative',
    width: '80px',
  },
});
