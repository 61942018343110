import { styled, Grid } from '@material-ui/core';

export const SafeArea = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '65px 5px 10px 55px',
  '@media (max-width:850px)': {
    paddingLeft: '5px',
    paddingBottom: '70px',
  },
});
