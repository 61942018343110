import React from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
} from '@material-ui/core';

import image1 from './images/1.png'
import image2 from './images/2.png'
import image3 from './images/3.png'
import image4 from './images/4.png'
import image5 from './images/5.png'
import image6 from './images/6.png'

import { ApplicationState } from '../../../store';
import { useStyles, Container } from './TutorialsStyles';

export default function Tutorials() {
  const userState = useSelector((state: ApplicationState) => state.user);

  const classes = useStyles();
  return (
    <>
      <Container container>
        <Typography variant="body1" className={classes.hiTitle}>
        Olá {userState.user.name}! Seja bem vindo ao DashTrader,<br/>abaixo temos um pequeno tutorial de como utilizar a plataforma:
        </Typography>
        <Typography variant="h6" className={classes.title}>1 - Adicionando trades</Typography>

        <Typography variant="body1">
          Para começar a ver as métricas das suas operações primeiro precisamos<br/>adicionar os registros das suas operações,
          existem duas formas de fazer isso:
        </Typography>
        <Typography variant="body1">
          A primeira é adicionando manualmente a cada operação.
        </Typography>
        <img alt="" src={image1} style={{ width: '780px' }} />
        <Typography variant="body1">
        A segunda é apenas para Operações feitas na IqOtion, baixando um arquivo com suas operações no período selecionado.
        </Typography>
        <img alt="" src={image2} style={{ width: '780px' }} />
        <Typography variant="body1">
        Após o download do CSV basta clicar em "FAZER UPLOAD" e selecionar o arquivo baixado.
        </Typography>
        <img alt="" src={image3} style={{ width: '400px' }} />

        <Typography variant="h6" className={classes.title}>2 - Adicionando depósitos e retiradas</Typography>
        <Typography variant="body1">
        Nesta tela você pode atualizar o saldo exibido no DashTrader e deixa-lo igual a sua corretora,<br/>
        então após cada depósito ou retirada na sua corretora, utilize esta página para atualizar seu saldo.

        </Typography>
        <img alt="" src={image4} style={{ width: '780px' }} />

        <Typography variant="h6" className={classes.title}>3 - Histórico de trades</Typography>
        <Typography variant="body1">
        Aqui você pode visualizar suas últimas operações e remove-las.
        </Typography>
        <img alt="" src={image5} style={{ width: '780px' }} />

          <Typography variant="h6" className={classes.title}>3 - Minha conta</Typography>
        <Typography variant="body1">  
        Altere seus dados cadastrais, e informe uma "Meta mensal" para acompanhar seu progresso na tela principal.
        </Typography>
        <img alt="" src={image6} style={{ width: '780px' }} />

        <Typography variant="body1" className={classes.finalText}>
        Pronto! Agora é só cadastrar suas operações ter suas métricas na palma da sua mão!
        </Typography>

      </Container>
    </>
  );
}
