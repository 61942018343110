import { styled } from '@material-ui/core/styles';
import { Form } from '@rocketseat/unform';
import { Box } from '@material-ui/core';

export const Container = styled(Box)({
  width: '100vw',
  height: '100vh',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: '7rem',
  padding: '0 2rem',
  '@media screen and (max-width: 1050px)': {
    gridGap: '5rem',
  },
  '@media screen and (max-width: 900px)': {
    gridTemplateColumns: '1fr',
  },
  '& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
    transition: 'background-color 5000s ease-in-out 0s',
  },
  '& input:-webkit-autofill': {
    WebkitTextFillColor: '#ffffffe0 !important',
    WebkitFontSizeDelta: '1.2rem !important',
  },
});

export const BackgroundWave = styled('img')({
  position: 'fixed',
  bottom: '0',
  left: '0',
  height: '100%',
  '@media screen and (max-width: 900px)': {
    display: 'none',
  },
});

export const LeftImg = styled(Box)({
  display: 'flex',

  justifyContent: 'flex-end',
  alignItems: 'center',
  '& img': {
    width: '500px',
  },
  '@media screen and (max-width: 1000px)': {
    '& img': {
      width: '400px',
    },
  },
  '@media screen and (max-width: 900px)': {
    display: 'none',
  },
});

export const FormContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  textAlign: 'center',
  '@media screen and (max-width: 900px)': {
    justifyContent: 'center',
  },
  '& img': {
    height: '100px',
  },
  '& .input-div': {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '7% 93%',
    margin: '25px 0',
    padding: '5px 0',
    borderBottom: '2px solid #d9d9d9',
  },
  '& .input-div.one': {
    marginTop: '10px',
  },
});

export const FormLogin = styled(Form)({
  maxWidth: '360px',
  width: '100%',
  '@media screen and (max-width: 1000px)': {
    maxWidth: '290px',
  },
  '& .input-div > div': {
    position: 'relative',
    height: '45px',
  },
  '& .input-div > div > h5': {
    position: 'absolute',
    left: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#999',
    fontSize: '18px',
    transition: '0.3s',
    margin: '0',
  },
  '& .input-div > div > span': {
    top: '54px',
    position: 'absolute',
    color: '#ec5674',
    left: '-16px',
  },
  '& .input-div:before, .input-div:after': {
    content: "''",
    position: 'absolute',
    bottom: '-2px',
    width: '0%',
    height: '2px',
    backgroundColor: '#ffee58',
    transition: '0.4s',
  },
  '& .input-div:before': {
    right: '50%',
  },
  '& .input-div:after': {
    left: '50%',
  },
  '& .input-div.focus:before, .input-div.focus:after': {
    width: '50%',
  },
  '& .input-div.focus > div > h5': {
    top: '-6px',
    fontSize: '15px',
  },
  '& .input-div.focus > div > svg': {
    color: '#ffee58',
  },
  '& .input-div > div > input': {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    border: 'none',
    outline: 'none',
    background: 'none',
    padding: '0.5rem 0.7rem',
    fontSize: '1.2rem',
    color: '#ffffffe0',
  },
  '& .input-div.pass': {
    marginBottom: '4px',
  },
  '& .submitButton': {
    width: '100%',
    marginTop: '30px !important',
    borderRadius: '20px !important',
    marginBottom: '15px',
  },
  '& .div': {
    height: '30px',
  },
});

export const Icon = styled(Box)({
  color: '#d9d9d9',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& svg': {
    transition: '0.3s',
  },
});
