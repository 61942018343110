import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box, useMediaQuery } from '@material-ui/core';

import BaseBox from '../_Common/BaseBox';
import NoData from '../_Common/NoData';
import CustomCircularProgress from '../_Common/CustomCircularProgress';
import { BodyBox, RowBox, ValueBox } from '../_Common/CommonComponents';
import {
  formatPrice,
  setPriceColor,
  dateToTimezone,
} from '../../../../../utils/functions';
import { useStyles } from './LastTradesStyles';
import { ApplicationState } from '../../../../../store';

interface Trade {
  date: string;
  asset: string;
  result: number;
  formattedResult: number;
  color: string;
}

export default function BestDaysOfWeek() {
  const classes = useStyles();
  const [parsedTrades, setParsedTrades] = useState<Array<Trade>>([]);
  const matches = useMediaQuery(
    '(max-width:425px), (min-width:600px) and (max-width:916px)'
  );

  const { loading, boxes } = useSelector(
    (state: ApplicationState) => state.boxes
  );

  useEffect(() => {
    if (boxes.LastTrades) {
      const parsedData = boxes.LastTrades.map((item: any) => {
        return {
          ...item,
          date: dateToTimezone(item.date),
          formattedResult: formatPrice(item.result),
          color: setPriceColor(item.result),
        };
      });

      setParsedTrades(parsedData);
    }
  }, [boxes.LastTrades]);

  function correctOTC(asset: String) {
    const arrayString = asset.split(' ');
    if (arrayString.length === 1) {
      return <Typography variant="body2">{arrayString[0]}</Typography>;
    }
    return (
      <Typography variant="body2">
        {arrayString[0]}
        {<span className={classes.otc}>{arrayString[1]}</span>}
      </Typography>
    );
  }

  return (
    <BaseBox title="Últimos trades">
      {!loading && parsedTrades.length !== 0 && (
        <BodyBox>
          <Box className={classes.boxLines}>
            <RowBox>
              <Typography
                style={{ marginRight: `${matches ? '55px' : '47px'}` }}
                className={classes.titles}
                variant="body2"
              >
                Data
              </Typography>
              <Typography
                style={{ marginRight: `${matches ? '32px' : '37px'}` }}
                className={classes.titles}
                variant="body2"
              >
                Ativo
              </Typography>
              <Typography
                style={{ marginRight: '20px' }}
                className={classes.titles}
                variant="body2"
              >
                Resultado
              </Typography>
            </RowBox>
            {parsedTrades.map(trade => (
              <RowBox key={Math.random()}>
                <ValueBox className={classes.largeValueBox}>
                  <Typography variant="caption">{trade.date}</Typography>
                </ValueBox>
                <ValueBox className={classes.divOtc}>
                  <Typography variant="body2">
                    {correctOTC(trade.asset)}
                  </Typography>
                </ValueBox>
                <ValueBox style={{ width: '100px' }}>
                  <Typography style={{ color: trade.color }} variant="body2">
                    {trade.formattedResult}
                  </Typography>
                </ValueBox>
              </RowBox>
            ))}
          </Box>
        </BodyBox>
      )}
      {loading && <CustomCircularProgress />}
      {!loading && parsedTrades.length === 0 && <NoData />}
    </BaseBox>
  );
}
