import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { VerifyDialog } from './VerifyEmailDialogStyles';

export default function VerifyEmailDialog({ open, closeModal }: { open: boolean, closeModal: Function }) {
  return (
    <>
      <VerifyDialog>
        <Dialog
          open={open}
          onClose={() => closeModal()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Verifique seu email</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Antes de fazer login verifique seu email, cheque sua caixa de entrada e spam.
            </DialogContentText>
          </DialogContent>
          <DialogActions>

            <Button onClick={() => closeModal()} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </VerifyDialog>
    </>
  );
}
