import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  containerValueBox: {
    marginLeft: '7px',
  },
  titles: {
    width: '60px',
    textAlign: 'center',
    '&:nth-child(-n+2)': {
      marginRight: '7px',
    },
  },
  hours: {
    width: 'max-content',
  },
}));
