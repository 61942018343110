import { makeStyles, Grid, styled } from '@material-ui/core';
import theme from '../../../styles/theme';

export const Container = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '90%',
  marginTop: '20px',
  background: theme.colors.backgroundLight,
  borderRadius: '4px',
  padding: '0px 50px',

  '& h6': {
    color: '#ffee58',
    padding: '0',
    marginBottom: '15px',
    width: '100%',
  },
  '& p': {
    padding: '0 20px 10px 20px',
    width: '100%',
  },
  '& img': {
    marginBottom: '35px',
    marginLeft: '25px',
    marginTop: '10px',
    maxWidth: '100%',
  }
});

export const useStyles = makeStyles({
  title: {
    marginTop: '15px',
    padding: '0 20px',
  },
  hiTitle: {
    textAlign: 'center',
    margin: '40px 0px',
    fontSize: '18px',
  },
  finalText: {
    margin: '40px 0px',
    fontSize: '18px',
  }
});
