/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
  Fab,
  Grid,
  Box,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import DateFnsUtils from '@date-io/date-fns';
import { format, parseISO } from 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { pt } from 'date-fns/locale';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import { headerAdd } from '../../../store/modules/header/actions';
import { correctPricePtBrToEn } from '../../../utils/functions';
import { BoxAdd, useStyles } from './DepositsAndWithdrawsStyles';
import { formatPrice } from '../../../utils/functions';

export default function TradesHistory() {
  interface DepositAndWithdraw {
    _id: string;
    date: string;
    type: string;
    value: number | string | any;
    formattedValue: number | string;
  }

  const [depositsAndWithdraws, setDepositsAndWithdraws] = useState<
    Array<DepositAndWithdraw>
  >([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:600px)');

  function request() {
    api
      .get('/depositsAndWithdraws')
      .then(res => {
        const { data } = res;
        const parsedData = data.map((item: any) => {
          return {
            ...item,
            date: format(parseISO(item.date.split('T')[0]), "dd'/'MM'/'yyyy"),
            formattedValue: formatPrice(item.value),
          };
        });
        setDepositsAndWithdraws(parsedData);
      })
      .catch(err => {
        toast.error(err.response.data.error);
      });
  }
  useEffect(() => {
    request();
  }, []);

  function handleDelete(item: DepositAndWithdraw) {
    api
      .delete('depositsAndWithdraws', {
        data: {
          _id: item._id,
        },
      })
      .then(res => {
        toast.success(res.data.message);

        setDepositsAndWithdraws(
          depositsAndWithdraws.filter(a => a._id !== item._id)
        );
        const correctValue = item.value;
        const valuePostiveOrNegative =
          item.type === 'Depósito' ? correctValue * -1 : correctValue * 1;

        dispatch(headerAdd(valuePostiveOrNegative));
      })
      .catch(err => {
        toast.error(err.response.data.error);
      });
  }

  async function handleAdd() {
    setLoading(true);
    api
      .post('depositsAndWithdraws', {
        date,
        type,
        value: correctPricePtBrToEn(value),
      })
      .then(res => {
        toast.success(res.data.message);
        const correctValue = correctPricePtBrToEn(value);
        const valuePostiveOrNegative =
          type === 'Depósito' ? correctValue * 1 : correctValue * -1;

        dispatch(headerAdd(valuePostiveOrNegative));
        request();
        setLoading(false);
      })
      .catch(err => {
        toast.error(err.response.data.error);
        setLoading(false);
      });

    setInputDate(new Date());
    setType('');
    setValue('');
  }

  const [date, setDate] = useState<string>(
    format(new Date(), "yyyy'-'MM'-'dd")
  );
  const [inputDate, setInputDate] = useState<Date | null>(new Date());
  const [type, setType] = useState('');
  const [value, setValue] = useState('');

  const handleDateChange = (selectedDate: Date | null) => {
    setInputDate(selectedDate);
    if (selectedDate != null) {
      setDate(format(selectedDate, "yyyy'-'MM'-'dd"));
    }
  };
  const handleTypeChange = (event: any) => {
    setType(event.target.value);
  };
  const handleValueChange = (event: any) => {
    setValue(event.target.value);
  };

  const classes = useStyles();
  return (
    <>
      <Typography variant="h5" className={classes.title}>
        Depósitos e retiradas
      </Typography>
      <BoxAdd>
        <Grid
          justify="space-evenly"
          alignItems="center"
          direction={matches ? 'row' : 'column'}
          container
        >
          <Grid xs={12} sm={12} md={2} lg={2} item>
            <Typography align="center" variant="body1">
              Adicionar
            </Typography>
          </Grid>
          <Grid xs={12} sm={4} md={2} lg={2} item>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
              <KeyboardDatePicker
                style={{ width: '164px' }}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Data"
                value={inputDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid xs={12} sm={4} md={2} lg={2} item>
            <FormControl className={classes.input}>
              <InputLabel id="type">Tipo</InputLabel>
              <Select
                labelId="type"
                id="type"
                value={type}
                onChange={handleTypeChange}
              >
                <MenuItem value="Depósito">Depósito</MenuItem>
                <MenuItem value="Retirada">Retirada</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={4} md={2} lg={2} item>
            <TextField
              id="value-number"
              label="Valor"
              placeholder="30,00"
              value={value}
              onChange={handleValueChange}
              style={{ maxWidth: '164px', marginTop: '6px' }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={1} lg={1} item>
            <Box className={classes.boxAddButton}>
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <Fab
                  onClick={handleAdd}
                  className={classes.addButton}
                  color="primary"
                >
                  <AddIcon />
                </Fab>
              )}
            </Box>
          </Grid>
        </Grid>
      </BoxAdd>
      <TableContainer component={Paper} className={classes.paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell align="center">Tipo</TableCell>
              <TableCell align="center">Valor</TableCell>
              <TableCell align="right">Remover</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {depositsAndWithdraws.map(item => (
              <TableRow className={classes.row} key={item._id}>
                <TableCell component="th" scope="row">
                  {item.date}
                </TableCell>
                <TableCell align="center">{item.type}</TableCell>
                <TableCell align="center">{item.formattedValue}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleDelete(item)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
