import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  titles: {
    width: '60px',
    textAlign: 'center',
    '&:nth-child(-n+2)': {
      marginRight: '7px',
    },
  },
  days: {
    background: 'transparent !important',
  },
});
