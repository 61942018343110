import { createStore, applyMiddleware, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

import { AuthState } from './modules/auth/types';
import { UserState } from './modules/user/types';
import { BoxesState } from './modules/boxes/types';
import { HeaderState } from './modules/header/types';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga'; // eslint-disable-line
import persistReducers from './persistReducers';

export interface ApplicationState {
  auth: AuthState;
  user: UserState;
  boxes: BoxesState;
  header: HeaderState;
}

const sagaMiddleware = createSagaMiddleware();

// eslint-disable-next-line
// @ts-ignore
const store: Store<ApplicationState> = createStore(
  persistReducers(rootReducer),
  applyMiddleware(sagaMiddleware)
);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
