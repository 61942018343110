import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography,
  Avatar,
  Container,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import { addDays, differenceInCalendarDays } from 'date-fns';
import {
  Logo,
  TotalBalance,
  MenuRight,
  useStyles,
  HeaderTooltip,
} from './HeaderStyles';
import { headerRequest } from '../../store/modules/header/actions';
import { ApplicationState } from '../../store';
import { formatPrice } from '../../utils/functions';
import logoimage from '../../files/images/logo.png';
import logoicon from '../../files/images/logo-icon.png';

export default function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const headerState = useSelector((state: ApplicationState) => state.header);
  const userState = useSelector((state: ApplicationState) => state.user);
  const matches = useMediaQuery('(min-width:714px)');
  const matches2 = useMediaQuery('(min-width:396px)');

  useEffect(() => {
    dispatch(headerRequest());
  }, []); // eslint-disable-line

  const regInitials = userState.user.name.match(/\b\w/g) || [];

  const initials = (
    (regInitials.shift() || '') + (regInitials.pop() || '')
  ).toUpperCase();

  const trialDaysLeft = differenceInCalendarDays(
    addDays(new Date(userState.user.createdAt), 7),
    new Date()
  );
  const parsedBalance = formatPrice(headerState.balance);

  return (
    <Container className={classes.container}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          width: '40%',
        }}
      >
        <Logo>
          {matches ? (
            <img alt="logo" src={logoimage} style={{ width: '220px' }} />
          ) : (
            <img alt="logo" src={logoicon} style={{ height: '40px' }} />
          )}
        </Logo>
        {!userState.user.isActive && trialDaysLeft > 0 && (
          <div style={{ marginLeft: '8px', marginBottom: '2px' }}>
            {trialDaysLeft} dias {matches2 && 'gratuitos'} restantes
          </div>
        )}
      </div>

      <TotalBalance>
        {(headerState.loading && <CircularProgress />) || (
          <Typography variant="h4" color="primary" component="h1">
            {parsedBalance}
          </Typography>
        )}
        <Typography variant="caption">saldo atual</Typography>
      </TotalBalance>
      <MenuRight>
        {!userState.user.isActive && (
          <a
            style={{
              padding: '8px 12px',
              background: '#ffee58',
              borderRadius: '4px',
              color: '#23284b',
            }}
            href="https://pay.kiwify.com.br/Ab8Dyb6"
            target="_blank"
            rel="noopener noreferrer"
          >
            Assinar {matches && 'premium'}
          </a>
        )}
        <Link to="/account">
          <HeaderTooltip title="Minha conta" placement="bottom">
            <Avatar className={classes.avatar}>{initials}</Avatar>
          </HeaderTooltip>
        </Link>
      </MenuRight>
    </Container>
  );
}
