import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api'; // eslint-disable-line
import { boxesSuccess, boxesFailure } from './actions';
import { BoxesTypes } from './types';

export function* getBalanceAndBoxesData() {
  try {
    const timeZoneDate = new Date();
    const offsetHours = timeZoneDate.getTimezoneOffset() / 60;
    const response = yield call(api.get, `/boxes/${offsetHours}`);
    const { boxes } = response.data;

    yield put(boxesSuccess(boxes));
  } catch (err) {
    toast.error(err.response.message, {
      toastId: 'homeError',
    });

    yield put(boxesFailure());
  }
}

export default all([
  takeLatest(BoxesTypes.BOXES_REQUEST, getBalanceAndBoxesData),
]);
