import React, { useState } from 'react';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import { Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import history from '../../../services/history';
import api from '../../../services/api';
import {
  Container,
  FormContainer,
  StyledForm,
  Icon,
} from './ValidateEmailStyles';
import GenerateNewTokenDialog from './GenerateNewTokenDialog';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
});

export default function ValidateEmail(props: any) {
  const [emailFocus, setEmailFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  function handleSubmit(): void {
    if (props.match.params.token) {
      setLoading(true);
      api
        .post('validateEmail', {
          email,
          token: props.match.params.token,
        })
        .then(res => {
          setLoading(false);
          toast.success(res.data.message);
          history.push('/login');
        })
        .catch(err => {
          setLoading(false);
          toast.error(err.response.data.error);
        });
    } else {
      toast.error(
        'É necessário fazer a validação pelo link enviado por email.'
      );
    }
  }

  function onInputFocus(): void {
    setEmailFocus(true);
  }
  function onInputBlur(): void {
    if (!email) {
      setEmailFocus(false);
    }
  }

  return (
    <>
      <Container>
        <FormContainer>
          <StyledForm onSubmit={handleSubmit} schema={schema}>
            <Typography color="primary" variant="h5">
              Digite seu email abaixo para valida-lo.
            </Typography>
            <br />
            <div className={`input-div one ${emailFocus ? 'focus' : ''}`}>
              <Icon>
                <EmailRoundedIcon fontSize="small" />
              </Icon>
              <div className="div">
                <h5>Email</h5>
                <Input
                  onFocus={() => onInputFocus()}
                  onBlur={() => onInputBlur()}
                  name="email"
                  type="email"
                  autoFocus
                  spellCheck={false}
                  onChange={(e: any) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <GenerateNewTokenDialog />
            <Button
              className="submitButton"
              variant="contained"
              color="primary"
              type="submit"
              disabled={!!loading}
            >
              {loading ? <CircularProgress size={24} /> : 'VALIDAR EMAIL'}
            </Button>
          </StyledForm>
        </FormContainer>
      </Container>
    </>
  );
}
