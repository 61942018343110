import React, { useState } from 'react';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import { Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import history from '../../../services/history';

import api from '../../../services/api';
import {
  Container,
  FormContainer,
  StyledForm,
  Icon,
} from './ForgotPasswordStyles';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  password: Yup.string()
    .min(6, 'A senha precisa ter no mínimo 6 caracteres')
    .required('A senha é obrigatória'),
  confirmPassword: Yup.string()
    .min(6, 'A senha precisa ter no mínimo 6 caracteres')
    .required('A senha é obrigatória'),
});

export default function ForgotPassword(props: any) {
  const [loading, setLoading] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  function handleSubmit(): void {
    if (props.match.params.token) {
      setLoading(true);
      api
        .post('forgotPassword', {
          email,
          password,
          confirmPassword,
          token: props.match.params.token,
        })
        .then(res => {
          setLoading(false);
          toast.success(res.data.message);
          history.push('/login');
        })
        .catch(err => {
          setLoading(false);
          toast.error(err.response.data.error);
        });
    } else {
      toast.error('É necessário alterar a senha pelo link enviado por email.');
    }
  }

  function onInputFocus(type: string): void {
    if (type === 'email') {
      setEmailFocus(true);
    } else if (type === 'password') {
      setPasswordFocus(true);
    } else if (type === 'confirmPassword') {
      setConfirmPasswordFocus(true);
    }
  }
  function onInputBlur(type: string): void {
    if (type === 'email' && !email) {
      setEmailFocus(false);
    } else if (type === 'password' && !password) {
      setPasswordFocus(false);
    } else if (type === 'confirmPassword' && !confirmPassword) {
      setConfirmPasswordFocus(false);
    }
  }

  return (
    <>
      <Container>
        <FormContainer>
          <StyledForm onSubmit={handleSubmit} schema={schema}>
            <Typography color="primary" variant="h5">
              Digite seu email e a nova senha para altera-la.
            </Typography>
            <br />
            <div className={`input-div one ${emailFocus ? 'focus' : ''}`}>
              <Icon>
                <EmailRoundedIcon fontSize="small" />
              </Icon>
              <div className="div">
                <h5>Email</h5>
                <Input
                  onFocus={() => onInputFocus('email')}
                  onBlur={() => onInputBlur('email')}
                  name="email"
                  type="email"
                  spellCheck={false}
                  onChange={(e: any) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className={`input-div one ${passwordFocus ? 'focus' : ''}`}>
              <Icon>
                <LockRoundedIcon fontSize="small" />
              </Icon>
              <div className="div">
                <h5>Nova senha</h5>
                <Input
                  onFocus={() => onInputFocus('password')}
                  onBlur={() => onInputBlur('password')}
                  name="password"
                  type="password"
                  spellCheck={false}
                  onChange={(e: any) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div
              className={`input-div one ${confirmPasswordFocus ? 'focus' : ''}`}
            >
              <Icon>
                <LockRoundedIcon fontSize="small" />
              </Icon>
              <div className="div">
                <h5>Confirmação de senha</h5>
                <Input
                  onFocus={() => onInputFocus('confirmPassword')}
                  onBlur={() => onInputBlur('confirmPassword')}
                  name="confirmPassword"
                  type="password"
                  spellCheck={false}
                  onChange={(e: any) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>

            <Button
              className="submitButton"
              variant="contained"
              color="primary"
              type="submit"
              disabled={!!loading}
            >
              {loading ? <CircularProgress size={24} /> : 'ALTERAR SENHA'}
            </Button>
          </StyledForm>
        </FormContainer>
      </Container>
    </>
  );
}
