import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

export default function CustomCircularProgress() {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '170px',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
