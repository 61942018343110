import { Reducer } from 'redux';
import produce from 'immer';
import { AuthState, AuthTypes } from './types';

const INITIAL_STATE: AuthState = {
  token: '',
  signed: false,
  loading: false,
  error: false,
};

const auth: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case AuthTypes.SIGN_IN_REQUEST: {
        draft.loading = true;
        break;
      }
      case AuthTypes.SIGN_IN_SUCCESS: {
        draft.token = action.payload.token;
        draft.signed = true;
        draft.loading = false;
        break;
      }
      case AuthTypes.LOGOUT: {
        draft.token = '';
        draft.signed = false;
        draft.loading = false;
        draft.error = false;
        break;
      }
      case AuthTypes.SIGN_IN_FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      default:
    }
  });
};

export default auth;
