import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { yellow } from '@material-ui/core/colors';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    colors: {
      primary: string,
      background: string,
      backgroundLight: string
      win: string,
      loss: string,
      whiteWeak: string
    };
    borderRadius: string,
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    colors: {
      primary: string,
      background: string,
      backgroundLight: string
      win: string,
      loss: string,
      whiteWeak: string
    };
    borderRadius: string,
  }
}

let theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: yellow[400],
    },
    background: {
      default: '#1E2141',
    },
  },
  colors: {
    primary: yellow[400],
    background: '#1E2141',
    backgroundLight: '#2C325C',
    win: '#4BDC4B',
    loss: '#FF6347',
    whiteWeak: '#ffffff99',
  },
  borderRadius: '10px',
});

export default theme = responsiveFontSizes(theme);
