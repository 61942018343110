import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';

import BaseBox from '../_Common/BaseBox';
import {
  BodyBox,
  RowBox,
  ValueBox,
  TextWin,
  TextLoss,
} from '../_Common/CommonComponents';
import { setPercentColor } from '../../../../../utils/functions';
import NoData from '../_Common/NoData';
import { useStyles } from './BestDaysOfWeekStyles';
import CustomCircularProgress from '../_Common/CustomCircularProgress';
import { ApplicationState } from '../../../../../store';

interface Days {
  dayOfWeek: number;
  qtyWin: number;
  qtyLoss: number;
  percentOfWin: number;
  color: string;
}

export default function BestDaysOfWeek() {
  const classes = useStyles();
  const [parsedDays, setParsedDays] = useState<Array<Days>>([]);
  const { loading, boxes } = useSelector(
    (state: ApplicationState) => state.boxes
  );

  function correctDayOfWeek(day: Number): String {
    switch (day) {
      case 1:
        return 'Domingo';
      case 2:
        return 'Segunda';
      case 3:
        return 'Terça';
      case 4:
        return 'Quarta';
      case 5:
        return 'Quinta';
      case 6:
        return 'Sexta';
      case 7:
        return 'Sábado';
      default:
        return 'Erro';
    }
  }

  useEffect(() => {
    if (boxes.BestDaysOfWeek) {
      const parsedData = boxes.BestDaysOfWeek.map((item: any) => {
        return {
          ...item,
          dayOfWeek: correctDayOfWeek(item.dayOfWeek),
          color: setPercentColor(item.percentOfWin),
        };
      });

      setParsedDays(parsedData);
    }
  }, [boxes.BestDaysOfWeek]);

  return (
    <BaseBox title="Dias da semana">
      {!loading && parsedDays.length !== 0 && (
        <BodyBox>
          <Box>
            <RowBox>
              <Typography className={classes.titles} variant="body2">
                %
              </Typography>
              <TextWin className={classes.titles} variant="body2">
                win
              </TextWin>
              <TextLoss className={classes.titles} variant="body2">
                loss
              </TextLoss>
            </RowBox>
            {parsedDays.map(item => (
              <RowBox key={item.dayOfWeek}>
                <Box className={classes.days}>{item.dayOfWeek}</Box>
                <ValueBox>
                  <Typography
                    style={{
                      color: `${item.color}`,
                    }}
                    variant="body2"
                  >
                    {item.percentOfWin}%
                  </Typography>
                </ValueBox>
                <ValueBox>
                  <Typography variant="body2">{item.qtyWin}</Typography>
                </ValueBox>
                <ValueBox>
                  <Typography variant="body2">{item.qtyLoss}</Typography>
                </ValueBox>
              </RowBox>
            ))}
          </Box>
        </BodyBox>
      )}
      {loading && <CustomCircularProgress />}
      {!loading && parsedDays.length === 0 && <NoData />}
    </BaseBox>
  );
}
