import { action } from 'typesafe-actions';
import { BoxesTypes, BoxesInterface } from './types';

export const boxesRequest = () => action(BoxesTypes.BOXES_REQUEST);

export const boxesSuccess = (boxes: BoxesInterface) =>
  action(BoxesTypes.BOXES_SUCCESS, { boxes });

export const boxesFailure = () => action(BoxesTypes.BOXES_FAILURE);

export const boxesSetPending = () => action(BoxesTypes.BOXES_SETPENDING);
