import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import { Input } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { signInRequest } from '../../../store/modules/auth/actions';
import { ApplicationState, persistor } from '../../../store';
import {
  Container,
  BackgroundWave,
  LeftImg,
  FormContainer,
  FormLogin,
  Icon,
} from './LoginStyles';
import personSvg from './Assets/bg.svg';
import avatar from './Assets/avatar.svg';
import wave from './Assets/wave.png';
// import ForgotPasswordDialog from './ForgotPasswordDialog';
import Header from '../Components/Header';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

export default function Login() {
  const dispatch = useDispatch();
  const loading = useSelector((state: ApplicationState) => state.auth.loading);
  const [emailFocus, setEmailFocus] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');

  useEffect(() => {
    setTimeout(() => {
      persistor.purge();
    }, 300);
  }, []);

  function handleSubmit(data: any): void {
    const { email, password } = data;

    dispatch(signInRequest(email, password));
  }

  function onInputFocus(type: string): void {
    if (type === 'email') {
      setEmailFocus(true);
    } else {
      setPasswordFocus(true);
    }
  }
  function onInputBlur(type: string): void {
    if (type === 'email' && !emailValue) {
      setEmailFocus(false);
    }
    if (type === 'password' && !passwordValue) {
      setPasswordFocus(false);
    }
  }

  return (
    <>
    <Header />
      <BackgroundWave src={wave} className="negative-zIndex" alt="background" />
      <Container>
        <LeftImg>
          <img src={personSvg} alt="loginPerson" />
        </LeftImg>
        <FormContainer>
          <FormLogin onSubmit={handleSubmit} schema={schema}>
            <img src={avatar} alt="avatar" />
            <Typography color="primary" variant="h4">
              Bem vindo!
            </Typography>

            <div className={`input-div one ${emailFocus ? 'focus' : ''}`}>
              <Icon>
                <EmailRoundedIcon fontSize="small" />
              </Icon>
              <div className="div">
                <h5>Email</h5>
                <Input
                  onFocus={() => onInputFocus('email')}
                  onBlur={() => onInputBlur('email')}
                  name="email"
                  type="email"
                  autoFocus
                  spellCheck={false}
                  onChange={(e: any) => setEmailValue(e.target.value)}
                />
              </div>
            </div>

            <div className={`input-div pass ${passwordFocus ? 'focus' : ''}`}>
              <Icon>
                <LockRoundedIcon fontSize="small" />
              </Icon>
              <div className="div">
                <h5>Senha</h5>
                <Input
                  onFocus={() => onInputFocus('password')}
                  onBlur={() => onInputBlur('password')}
                  name="password"
                  type="password"
                  onChange={(e: any) => setPasswordValue(e.target.value)}
                />
              </div>
            </div>
            {/* <ForgotPasswordDialog /> */}
            <Button
              className="submitButton"
              variant="contained"
              color="primary"
              type="submit"
              disabled={!!loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Entrar'}
            </Button>
            <Link to="/register" style={{ color: '#fff' }}>Ainda não possui uma conta? <Typography color="primary" variant="body2" style={{ display: 'inline-block' }}>Inscrever-se</Typography></Link>
          </FormLogin>
        </FormContainer>
      </Container>
    </>
  );
}
