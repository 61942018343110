import React from 'react';

import {
  Container,
  BackgroundWave,
  LeftImg,
  RightContentContainer,
} from './TrialExpiredStyles';
import wave from './Assets/wave.png';
import alertSvg from './Assets/alert.svg';
import Header from '../Components/Header';
import { Typography } from '@material-ui/core';

export default function TrialExpired() {
  return (
    <>
      <Header />
      <BackgroundWave src={wave} className="negative-zIndex" alt="background" />
      <Container>
        <LeftImg>
          <img src={alertSvg} alt="alert" />
        </LeftImg>
        <RightContentContainer>
          <Typography color="primary" variant="h4">
            Seu período de testes acabou?
          </Typography>
          <Typography variant="body2">
            Assine o premium e recupere seu acesso<br></br>o quanto antes!
          </Typography>
          <a
            style={{
              padding: '8px 12px',
              background: '#ffee58',
              borderRadius: '4px',
              color: '#23284b',
              width: '133px',
            }}
            href="https://pay.kiwify.com.br/Ab8Dyb6"
            target="_blank"
            rel="noopener noreferrer"
          >
            Assinar premium
          </a>
        </RightContentContainer>
      </Container>
    </>
  );
}
